import React, { useState } from 'react';
import { X } from 'lucide-react';
import { Button } from '../ui/Button';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

interface AccountPromptDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onContinueAsGuest: () => void;
  eventId: string;
  quantity: number;
}

export function AccountPromptDialog({
  isOpen,
  onClose,
  onContinueAsGuest,
  eventId,
  quantity
}: AccountPromptDialogProps) {
  const navigate = useNavigate();
  const { signInWithGoogle } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleCreateAccount = () => {
    // Store checkout details in URL params for after signup
    const params = new URLSearchParams();
    params.set('action', 'checkout');
    params.set('quantity', quantity.toString());
    
    // Redirect to signup with return URL to this event
    navigate(`/signup?${params.toString()}&returnTo=${encodeURIComponent(`/events/${eventId}`)}`);
    onClose();
  };

  const handleGoogleSignIn = async () => {
    try {
      setLoading(true);
      setError(null);
      
      // Store checkout details in localStorage for after Google sign-in
      localStorage.setItem('bookingId', eventId);
      localStorage.setItem('bookingQuantity', quantity.toString());
      localStorage.setItem('returnTo', `/events/${eventId}`);
      localStorage.setItem('postGoogleAuthBookingId', eventId);
      localStorage.setItem('postGoogleAuthReturnTo', `/events/${eventId}`);
      
      console.log('Stored event details for Google sign-in:', {
        eventId,
        quantity,
        returnTo: `/events/${eventId}`
      });
      
      // Initiate Google sign-in process
      await signInWithGoogle();
      // The GoogleCallback component will handle the redirect back
    } catch (error) {
      console.error('Google sign in error:', error);
      setError('Failed to sign in with Google. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`
      fixed inset-0 flex items-center justify-center z-50
      transition-opacity duration-300
      ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}
    `}>
      <div className="absolute inset-0 bg-black/50 backdrop-blur-sm" onClick={onClose} />
      <div className={`
        relative bg-white rounded-2xl p-8 max-w-md w-full mx-4
        transform transition-all duration-300 shadow-xl
        ${isOpen ? 'scale-100' : 'scale-95'}
      `}>
        <button 
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
          type="button"
          aria-label="Close dialog"
        >
          <X className="h-6 w-6" />
        </button>

        <div className="text-center">
          <h3 className="text-2xl font-bold text-gray-900 mb-2">
            Sign In to Continue
          </h3>
          <p className="text-gray-600 mb-6">
            Sign in or create an account to complete your booking and track your reservations.
          </p>

          {error && (
            <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-md text-sm">
              {error}
            </div>
          )}

          <div className="space-y-3">
            <Button
              onClick={handleGoogleSignIn}
              disabled={loading}
              className="w-full flex items-center justify-center gap-2"
            >
              <img
                src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                alt="Google"
                className="w-5 h-5"
              />
              {loading ? 'Connecting...' : 'Continue with Google'}
            </Button>
            
            <Button
              onClick={handleCreateAccount}
              variant="outline"
              className="w-full"
              disabled={loading}
            >
              Sign Up with Email
            </Button>
          </div>

          <p className="mt-4 text-xs text-gray-500">
            By creating an account, you'll get early access to new events and be able to track your bookings.
          </p>
        </div>
      </div>
    </div>
  );
} 