import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

export function GoogleCallback() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { handleGoogleCallback } = useAuth();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState<'processing' | 'success' | 'error'>('processing');

  useEffect(() => {
    async function completeSignIn() {
      try {
        setStatus('processing');
        console.log('GoogleCallback: Started callback processing');
        console.log('GoogleCallback: URL params:', {
          fullUrl: window.location.href,
          searchParams: Object.fromEntries(searchParams.entries())
        });
        
        // Check for error in URL parameters
        const urlError = searchParams.get('error');
        if (urlError) {
          console.error('GoogleCallback: URL contains error:', urlError);
          throw new Error(urlError === 'access_denied' 
            ? 'Access was denied. Please try again.' 
            : 'Authentication failed. Please try again.');
        }

        // Check localStorage for stored values
        console.log('GoogleCallback: Checking localStorage for stored values');
        const bookingId = localStorage.getItem('bookingId') || localStorage.getItem('postGoogleAuthBookingId');
        const storedRedirect = localStorage.getItem('postGoogleAuthRedirect');
        const storedReturnTo = localStorage.getItem('returnTo') || localStorage.getItem('postGoogleAuthReturnTo');
        
        console.log('GoogleCallback: Found stored values:', {
          bookingId,
          storedRedirect,
          storedReturnTo
        });

        console.log('GoogleCallback: Calling handleGoogleCallback');
        await handleGoogleCallback();
        console.log('GoogleCallback: Successfully completed handleGoogleCallback');
        
        // Set success status
        setStatus('success');
        
        // Clear the localStorage values
        localStorage.removeItem('bookingId');
        localStorage.removeItem('bookingQuantity');
        localStorage.removeItem('returnTo');
        localStorage.removeItem('postGoogleAuthBookingId');
        localStorage.removeItem('postGoogleAuthRedirect');
        localStorage.removeItem('postGoogleAuthReturnTo');
        
        // Get the return URL from query parameters
        const returnTo = searchParams.get('returnTo') || storedReturnTo;
        console.log('GoogleCallback: Return URL:', returnTo);
        
        // Determine the target path, with preference to stored redirect if available
        let targetPath;
        
        if (bookingId && storedRedirect) {
          // If we have both a booking ID and redirect URL, add the booking ID to the redirect
          targetPath = `${storedRedirect}?booking_id=${bookingId}`;
        } else if (bookingId) {
          // If we only have a booking ID, go to the event page
          targetPath = `/events/${bookingId}`;
        } else if (storedRedirect) {
          // If we only have a redirect URL
          targetPath = storedRedirect;
        } else {
          // Otherwise use the returnTo parameter or default to events page
          targetPath = returnTo ? decodeURIComponent(returnTo) : '/events';
        }
        
        console.log('GoogleCallback: Navigating to:', targetPath);
        
        // Short delay to show success message
        setTimeout(() => {
          navigate(targetPath, { replace: true });
        }, 1000);
      } catch (error: any) {
        console.error('GoogleCallback: Error during callback processing:', error);
        
        // Set error status
        setStatus('error');
        
        // Handle specific error codes
        if (error.code === 409) {
          console.log('GoogleCallback: User already exists (409), treating as successful sign-in');
          // For 409 errors (user already exists), treat it as a successful sign-in
          setStatus('success');
          
          // Check localStorage for stored values again
          const bookingId = localStorage.getItem('bookingId') || localStorage.getItem('postGoogleAuthBookingId');
          const storedReturnTo = localStorage.getItem('returnTo') || localStorage.getItem('postGoogleAuthReturnTo');
          
          // Clear localStorage
          localStorage.removeItem('bookingId');
          localStorage.removeItem('bookingQuantity');
          localStorage.removeItem('returnTo');
          localStorage.removeItem('postGoogleAuthBookingId');
          localStorage.removeItem('postGoogleAuthRedirect');
          localStorage.removeItem('postGoogleAuthReturnTo');
          
          // Determine where to navigate
          let targetPath = '/events';
          if (bookingId) {
            targetPath = `/events/${bookingId}`;
          } else if (storedReturnTo) {
            targetPath = storedReturnTo;
          }
          
          setTimeout(() => {
            navigate(targetPath, { replace: true });
          }, 1000);
          return;
        }
        
        setError(error.message || 'Failed to complete sign in');
        setTimeout(() => {
          // Preserve any error message in the URL when redirecting
          const loginUrl = new URL('/login', window.location.origin);
          if (error.message) {
            loginUrl.searchParams.set('error', error.message);
          }
          console.log('GoogleCallback: Redirecting to login with error:', loginUrl.toString());
          navigate(loginUrl.pathname + loginUrl.search, { replace: true });
        }, 3000);
      } finally {
        setLoading(false);
      }
    }

    completeSignIn();
  }, [navigate, handleGoogleCallback, searchParams]);

  if (error) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center p-4">
        <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-4 max-w-md text-center">
          <p className="font-semibold mb-2">Authentication Error</p>
          <p>{error}</p>
        </div>
        <p className="text-gray-600">Redirecting to login page...</p>
      </div>
    );
  }

  if (status === 'success') {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center p-4">
        <div className="bg-green-50 text-green-600 p-4 rounded-lg mb-4 max-w-md text-center">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mx-auto mb-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
          </svg>
          <p className="font-semibold mb-2">Authentication Successful</p>
          <p>You have successfully signed in!</p>
        </div>
        <p className="text-gray-600">Redirecting you now...</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-4">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500 mb-4"></div>
      <h2 className="text-xl font-semibold text-gray-900 mb-2">Completing Sign In</h2>
      <p className="text-gray-600">Please wait ...</p>
    </div>
  );
} 