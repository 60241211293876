import React, { useState, useMemo } from 'react';
import { useEvents } from '../hooks/useEvents';
import { EventCard } from '../components/events/EventCard';
import { Button } from '../components/ui/Button';
import { Card } from '../components/ui/Card';
import { Filter, Calendar, MapPin, Users } from 'lucide-react';
import { WaitlistDialog } from '../components/ui/WaitlistDialog';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { cn } from '../lib/utils';
import { formatEventTime } from '../utils/date';

export function EventsPage() {
  const [selectedCity, setSelectedCity] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [showWaitlist, setShowWaitlist] = useState(false);
  const [filter, setFilter] = useState<'upcoming' | 'past'>('upcoming');
  const { events, loading, error } = useEvents({ city: selectedCity });

  const cities = ['Toronto', 'Vancouver', 'Montreal', 'Calgary', 'Ottawa'];

  const filteredEvents = useMemo(() => {
    if (!events) return [];
    const now = new Date();

    return events
      .filter(event => {
        // Create a date object with both date and time components
        const [hours, minutes] = event.time.split(':');
        const eventDateTime = new Date(event.date);
        
        // Try to parse time - handle different time formats
        let hourNum = parseInt(hours);
        let isPM = false;
        
        // Check if time includes AM/PM
        if (event.time.includes('PM') && hourNum !== 12) {
          isPM = true;
        }
        
        // Set hours accounting for PM if needed
        eventDateTime.setHours(
          isPM ? hourNum + 12 : hourNum, 
          parseInt(minutes) || 0
        );

        const matchesTimeFilter = filter === 'upcoming' 
          ? eventDateTime >= now 
          : eventDateTime < now;
        const matchesCity = !selectedCity || event.city === selectedCity;
        
        return matchesTimeFilter && matchesCity;
      })
      .sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        const aIsSoldOut = a.registered >= a.capacity;
        const bIsSoldOut = b.registered >= b.capacity;

        if (filter === 'upcoming') {
          // First sort by sold out status
          if (aIsSoldOut !== bIsSoldOut) {
            return aIsSoldOut ? 1 : -1;
          }
          // Then by date
          return dateA.getTime() - dateB.getTime();
        }
        // Past events sort by most recent first
        return dateB.getTime() - dateA.getTime();
      });
  }, [events, filter, selectedCity]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 text-center">
        <div className="bg-red-50 text-red-700 p-4 rounded-md">
          {error}
        </div>
        <Button onClick={() => window.location.reload()} className="mt-4">
          Try Again
        </Button>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 sm:py-8">
      {/* Header */}
      <div className="text-center mb-6 sm:mb-8">
        <h1 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-2">
          {selectedCity 
            ? `Events in ${selectedCity}` 
            : filter === 'upcoming' 
              ? 'Upcoming Events' 
              : 'Past Events'
          }
        </h1>
        <p className="text-lg sm:text-xl text-gray-600">
          Experience every dish on the menu at Canada's finest restaurants
          <span className="block text-sm text-gray-500 mt-2">
            Limited seats available • Book early to avoid disappointment
          </span>
        </p>
      </div>

      {/* Sort Options */}
      <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-4 mb-6">
        <div className="flex gap-2">
          <Button 
            variant={filter === 'upcoming' ? 'primary' : 'secondary'}
            className={`flex-1 sm:flex-none text-sm ${filter === 'upcoming' ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}
            onClick={() => setFilter('upcoming')}
          >
            Upcoming Events
          </Button>
          <Button 
            variant={filter === 'past' ? 'primary' : 'secondary'}
            className={`flex-1 sm:flex-none text-sm ${filter === 'past' ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}
            onClick={() => setFilter('past')}
          >
            Past Events
          </Button>
        </div>
        <Button
          onClick={() => setShowFilters(!showFilters)}
          variant="outline"
          className="sm:hidden flex items-center justify-center gap-2"
        >
          <Filter className="w-4 h-4" />
          {showFilters ? 'Hide Filters' : 'Show Filters'}
        </Button>
      </div>

      <div className="flex flex-col lg:flex-row gap-6">
        {/* Filters */}
        <Card className={`lg:w-64 flex-shrink-0 ${showFilters ? 'block' : 'hidden lg:block'}`}>
          <div className="p-4">
            <h2 className="font-semibold text-lg mb-4">Filter by City</h2>
            <div className="space-y-2">
              <button
                onClick={() => {
                  setSelectedCity('');
                  setShowFilters(false);
                }}
                className={`w-full text-left px-3 py-2 rounded-md ${
                  selectedCity === '' 
                    ? 'bg-gray-100 text-gray-900' 
                    : 'hover:bg-gray-50'
                }`}
              >
                All Cities
              </button>
              {cities.map(city => (
                <button
                  key={city}
                  onClick={() => {
                    setSelectedCity(city);
                    setShowFilters(false);
                  }}
                  className={`w-full text-left px-3 py-2 rounded-md ${
                    selectedCity === city 
                      ? 'bg-gray-100 text-gray-900' 
                      : 'hover:bg-gray-50'
                  }`}
                >
                  {city}
                </button>
              ))}
            </div>
          </div>
        </Card>

        {/* Events Grid */}
        <div className="flex-1">
          {filteredEvents.length > 0 ? (
            <>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
                {filteredEvents.map(event => {
                  const isSoldOut = event.registered >= event.capacity;
                  const isPastEvent = new Date(event.date) < new Date();
                  const spotsLeft = event.capacity - event.registered;
                  
                  return (
                    <Link
                      key={event.$id}
                      to={`/events/${event.$id}`}
                      className={cn(
                        "block rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-shadow",
                        isPastEvent && "opacity-75"
                      )}
                    >
                      <div className="relative">
                        <img
                          src={event.imageUrl}
                          alt={event.title}
                          className="w-full h-48 object-cover"
                        />
                        {isSoldOut && !isPastEvent && (
                          <div className="absolute top-2 right-2 bg-red-500 text-white px-3 py-1 rounded-full text-sm font-medium">
                            Sold Out
                          </div>
                        )}
                        {isPastEvent && (
                          <div className="absolute top-2 right-2 bg-gray-500 text-white px-3 py-1 rounded-full text-sm font-medium">
                            Past Event
                          </div>
                        )}
                      </div>
                      <div className="p-4">
                        <h2 className="text-lg sm:text-xl font-semibold text-gray-900 mb-2">
                          {event.title}
                        </h2>
                        <div className="text-gray-600 space-y-2 mb-4 text-sm">
                          <div className="flex items-center gap-2">
                            <Calendar className="h-4 w-4 flex-shrink-0" />
                            <span className="line-clamp-1">
                              {format(new Date(event.date), 'MMMM d, yyyy')} at {formatEventTime(event.time)}
                            </span>
                          </div>
                          <div className="flex items-center gap-2">
                            <MapPin className="h-4 w-4 flex-shrink-0" />
                            <span>{event.city}</span>
                          </div>
                          {!isPastEvent && (
                            <div className="flex items-center gap-2">
                              <Users className="h-4 w-4 flex-shrink-0" />
                              <span>
                                {isSoldOut ? (
                                  <span className="text-red-500 font-medium">Sold Out</span>
                                ) : spotsLeft === 1 ? (
                                  'Only 1 spot left!'
                                ) : (
                                  `${spotsLeft} spots left`
                                )}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="flex justify-end">
                          <span className="text-gray-900 font-medium text-lg">
                            ${event.price}
                          </span>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
              <div className="mt-8 text-center text-gray-600">
                <p className="text-sm">
                  Don't see what you're looking for?
                  <Button 
                    variant="outline" 
                    className="ml-2"
                    onClick={() => setShowWaitlist(true)}
                  >
                    Join Waitlist
                  </Button>
                </p>
              </div>
            </>
          ) : (
            <Card>
              <div className="p-6 text-center text-gray-500">
                <h3 className="text-lg font-medium mb-2">No Events Found</h3>
                <p>Try adjusting your filters or check back later for new events.</p>
              </div>
            </Card>
          )}
        </div>
      </div>

      <WaitlistDialog 
        isOpen={showWaitlist} 
        onClose={() => setShowWaitlist(false)} 
      />
    </div>
  );
}