import React, { useState } from 'react';
import { Card } from './Card';
import { Button } from './Button';
import { useAuth } from '../../contexts/AuthContext';

export function WaitlistCard() {
  const { user } = useAuth();
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  // If user is logged in, don't show the waitlist card
  if (user) {
    return null;
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('form-name', 'waitlist');
      formData.append('email', email);

      await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(formData as any).toString()
      });

      setSubmitted(true);
      setEmail('');
    } catch (error) {
      console.error('Form submission error:', error);
    }
  };

  return (
    <Card>
      <div className="p-6">
        <h3 className="font-semibold text-lg mb-2">Join the Waitlist</h3>
        <p className="text-gray-600 text-sm mb-4">
          Be the first to know about new events and exclusive dining experiences.
        </p>

        {submitted ? (
          <div className="text-green-600 text-sm">
            Thanks for joining! We'll be in touch.
          </div>
        ) : (
          <form 
            onSubmit={handleSubmit} 
            name="waitlist" 
            method="POST" 
            data-netlify="true"
            netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="waitlist" />
            <div hidden>
              <input name="bot-field" />
            </div>
            <div className="space-y-3">
              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-gray-900 focus:border-gray-900"
              />
              <Button type="submit" className="w-full">
                Join Waitlist
              </Button>
            </div>
          </form>
        )}
      </div>
    </Card>
  );
} 