import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Card } from '../../components/ui/Card';
import { Button } from '../../components/ui/Button';
import { PencilIcon } from 'lucide-react';
import { account, storage, ID, databases } from '../../lib/appwrite';
import { appwriteConfig } from '../../config/appwrite';
import { Query } from 'appwrite';

interface User {
  $id: string;
  userId: string;
  email: string;
  name: string;
  role: string;
  createdAt: string;
  updatedAt: string;
  phone?: string;
  city?: string;
  avatar?: string;
  restaurantId?: string | null;
  eventUpdates?: boolean;
  newEventsUpdate?: boolean;
}

export function SettingsPage() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [message, setMessage] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [phoneNumber, setPhoneNumber] = useState(user?.phone || '');
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [userDocument, setUserDocument] = useState<User | null>(null);
  const [emailPreferences, setEmailPreferences] = useState({
    eventUpdates: true,
    newEventsUpdate: true
  });
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  useEffect(() => {
    async function fetchUserDocument() {
      if (!user) return;
      
      try {
        setPageLoading(true);
        const response = await databases.listDocuments(
          appwriteConfig.databaseId,
          appwriteConfig.usersCollectionId,
          [Query.equal('userId', user.userId)]
        );

        if (response.documents.length > 0) {
          const doc = response.documents[0];
          const userDoc = {
            $id: doc.$id,
            userId: doc.userId,
            email: doc.email,
            name: doc.name,
            role: doc.role,
            createdAt: doc.createdAt,
            updatedAt: doc.updatedAt,
            phone: doc.phone,
            city: doc.city,
            avatar: doc.avatar,
            restaurantId: doc.restaurantId,
            eventUpdates: doc.eventUpdates ?? true,
            newEventsUpdate: doc.newEventsUpdate ?? true
          };
          setUserDocument(userDoc);
          setPhoneNumber(doc.phone || '');
          setEmailPreferences({
            eventUpdates: doc.eventUpdates ?? true,
            newEventsUpdate: doc.newEventsUpdate ?? true
          });
        }
      } catch (error) {
        console.error('Failed to fetch user document:', error);
        setMessage('Failed to load user data');
      } finally {
        setPageLoading(false);
      }
    }

    fetchUserDocument();
  }, [user]);

  if (!user) {
    return null;
  }

  if (pageLoading || !userDocument) {
    return (
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="animate-pulse">
          <div className="h-8 w-48 bg-gray-200 rounded mb-8"></div>
          <div className="space-y-6">
            <div className="h-64 bg-gray-200 rounded"></div>
          </div>
        </div>
      </div>
    );
  }

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setLoading(true);
      setMessage('Uploading image...');

      // 1. Upload file to storage
      const response = await storage.createFile(
        appwriteConfig.userAvatarsBucketId,
        ID.unique(),
        file
      );

      // 2. Get the file URL
      const fileUrl = storage.getFileView(
        appwriteConfig.userAvatarsBucketId,
        response.$id
      );

      // 3. Find the user document
      const userDocs = await databases.listDocuments(
        appwriteConfig.databaseId,
        appwriteConfig.usersCollectionId,
        [Query.equal('userId', user.$id)]
      );

      if (userDocs.documents.length === 0) {
        throw new Error('User document not found');
      }

      const userDoc = userDocs.documents[0];

      console.log('Updating user document:', {
        documentId: userDoc.$id,
        avatar: fileUrl
      });

      // 4. Update user document in the database
      await databases.updateDocument(
        appwriteConfig.databaseId,
        appwriteConfig.usersCollectionId,
        userDoc.$id,
        {
          avatar: fileUrl,
          updatedAt: new Date().toISOString()
        }
      );

      setMessage('Profile picture updated successfully');
      
      // 5. Reload the page to show the new avatar
      window.location.reload();
    } catch (error) {
      console.error('Failed to upload image:', error);
      console.error('Error details:', {
        user: {
          authId: user.$id,
          name: user.name
        },
        databaseId: appwriteConfig.databaseId,
        collectionId: appwriteConfig.usersCollectionId
      });
      setMessage(error instanceof Error ? error.message : 'Failed to update profile picture');
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordChange = async (e: React.FormEvent) => {
    e.preventDefault();
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      setMessage('New passwords do not match');
      return;
    }

    try {
      setLoading(true);
      await account.updatePassword(passwordData.newPassword, passwordData.currentPassword);
      setMessage('Password updated successfully');
      setShowPasswordChange(false);
      setPasswordData({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      });
    } catch (error) {
      console.error('Failed to update password:', error);
      setMessage('Failed to update password');
    } finally {
      setLoading(false);
    }
  };

  const handleProfileUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const name = formData.get('name') as string;
    const phone = formData.get('phone') as string;

    try {
      setLoading(true);

      // 1. Find the user document
      const userDocs = await databases.listDocuments(
        appwriteConfig.databaseId,
        appwriteConfig.usersCollectionId,
        [Query.equal('userId', user.$id)]
      );

      if (userDocs.documents.length === 0) {
        throw new Error('User document not found');
      }

      const userDoc = userDocs.documents[0];

      // 2. Update the document
      await databases.updateDocument(
        appwriteConfig.databaseId,
        appwriteConfig.usersCollectionId,
        userDoc.$id,
        {
          name,
          phone,
          updatedAt: new Date().toISOString()
        }
      );
      setMessage('Profile updated successfully');
      window.location.reload();
    } catch (error) {
      console.error('Failed to update profile:', error);
      setMessage('Failed to update profile');
    } finally {
      setLoading(false);
    }
  };

  const handleEmailPreferencesUpdate = async () => {
    if (!userDocument) return;

    try {
      setLoading(true);
      setMessage('Updating preferences...');

      await databases.updateDocument(
        appwriteConfig.databaseId,
        appwriteConfig.usersCollectionId,
        userDocument.$id,
        {
          eventUpdates: emailPreferences.eventUpdates,
          newEventsUpdate: emailPreferences.newEventsUpdate,
          updatedAt: new Date().toISOString()
        }
      );

      setMessage('Email preferences updated successfully');
    } catch (error) {
      console.error('Failed to update email preferences:', error);
      setMessage('Failed to update email preferences');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Account Settings</h1>

      {message && (
        <div className={`p-4 rounded-md mb-6 ${message.includes('success') ? 'bg-green-50 text-green-700' : 'bg-red-50 text-red-700'}`}>
          {message}
        </div>
      )}

      <div className="space-y-6">
        <Card className="p-6">
          <h2 className="text-xl font-semibold text-gray-900 mb-4">Profile Information</h2>
          <form onSubmit={handleProfileUpdate} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Profile Picture
              </label>
              <div className="mt-2 flex items-center space-x-4">
                <div className="relative">
                  <img
                    src={userDocument.avatar || `https://ui-avatars.com/api/?name=${encodeURIComponent(user.name)}`}
                    alt={user.name}
                    className="h-16 w-16 rounded-full object-cover"
                  />
                  <Button
                    type="button"
                    onClick={() => fileInputRef.current?.click()}
                    className="absolute bottom-0 right-0 rounded-full p-1"
                    disabled={loading}
                  >
                    <PencilIcon className="h-3 w-3" />
                  </Button>
                </div>
                <input
                  type="file"
                  ref={fileInputRef}
                  className="hidden"
                  accept="image/*"
                  onChange={handleImageUpload}
                  aria-label="Upload profile picture"
                />
              </div>
            </div>

            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                defaultValue={user.name}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
              />
            </div>

            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                type="email"
                id="email"
                defaultValue={user.email}
                disabled
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm bg-gray-50"
              />
            </div>

            <div>
              <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                Phone Number
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
              />
            </div>

            <div className="flex justify-end">
              <Button type="submit" disabled={loading}>
                {loading ? 'Saving...' : 'Save Changes'}
              </Button>
            </div>
          </form>
        </Card>

        <Card className="p-6">
          <h2 className="text-xl font-semibold text-gray-900 mb-4">Email Preferences</h2>
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-base font-medium text-gray-900">Event Updates</h3>
                <p className="text-sm text-gray-600">
                  Receive updates about your upcoming events
                </p>
              </div>
              <input
                type="checkbox"
                id="event-updates"
                checked={emailPreferences.eventUpdates}
                onChange={(e) => setEmailPreferences(prev => ({
                  ...prev,
                  eventUpdates: e.target.checked
                }))}
                className="h-4 w-4 rounded border-gray-300 text-purple-600 focus:ring-purple-500"
                aria-label="Receive event updates"
              />
            </div>

            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-base font-medium text-gray-900">New Events</h3>
                <p className="text-sm text-gray-600">
                  Get notified about new events in your area
                </p>
              </div>
              <input
                type="checkbox"
                id="new-events"
                checked={emailPreferences.newEventsUpdate}
                onChange={(e) => setEmailPreferences(prev => ({
                  ...prev,
                  newEventsUpdate: e.target.checked
                }))}
                className="h-4 w-4 rounded border-gray-300 text-purple-600 focus:ring-purple-500"
                aria-label="Receive new event notifications"
              />
            </div>

            <div className="flex justify-end">
              <Button
                type="button"
                onClick={handleEmailPreferencesUpdate}
                disabled={loading}
              >
                {loading ? 'Saving...' : 'Save Preferences'}
              </Button>
            </div>
          </div>
        </Card>

        <Card className="p-6">
          <h2 className="text-xl font-semibold text-gray-900 mb-4">Password</h2>
          <div className="space-y-4">
            <Button
              type="button"
              onClick={() => setShowPasswordChange(!showPasswordChange)}
              variant="outline"
              className="w-full"
            >
              {showPasswordChange ? 'Cancel Password Change' : 'Change Password'}
            </Button>

            {showPasswordChange && (
              <form onSubmit={handlePasswordChange} className="space-y-4">
                <div>
                  <label htmlFor="current-password" className="block text-sm font-medium text-gray-700">
                    Current Password
                  </label>
                  <input
                    type="password"
                    id="current-password"
                    value={passwordData.currentPassword}
                    onChange={(e) => setPasswordData(prev => ({
                      ...prev,
                      currentPassword: e.target.value
                    }))}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                  />
                </div>

                <div>
                  <label htmlFor="new-password" className="block text-sm font-medium text-gray-700">
                    New Password
                  </label>
                  <input
                    type="password"
                    id="new-password"
                    value={passwordData.newPassword}
                    onChange={(e) => setPasswordData(prev => ({
                      ...prev,
                      newPassword: e.target.value
                    }))}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                  />
                </div>

                <div>
                  <label htmlFor="confirm-password" className="block text-sm font-medium text-gray-700">
                    Confirm New Password
                  </label>
                  <input
                    type="password"
                    id="confirm-password"
                    value={passwordData.confirmPassword}
                    onChange={(e) => setPasswordData(prev => ({
                      ...prev,
                      confirmPassword: e.target.value
                    }))}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                  />
                </div>

                <div className="flex justify-end">
                  <Button type="submit" disabled={loading}>
                    {loading ? 'Updating...' : 'Update Password'}
                  </Button>
                </div>
              </form>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
}