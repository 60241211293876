import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { Card } from '../../components/ui/Card';
import { databases } from '../../lib/appwrite';
import { appwriteConfig } from '../../config/appwrite';
import { Query } from 'appwrite';
import { format } from 'date-fns';
import { ChevronRight } from 'lucide-react';
import { eventService } from '../../services/database';

interface Booking {
  $id: string;
  userId: string;
  eventId: string;
  event: {
    title: string;
    date: string;
    time: string;
    imageUrl: string;
    restaurantName: string;
    address: string;
  };
  quantity: number;
  totalAmount: number;
  status: string;
  createdAt: string;
}

export function BookingsPage() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchBookings() {
      if (!user) return;

      try {
        setLoading(true);
        setError(null);

        const response = await databases.listDocuments(
          appwriteConfig.databaseId,
          appwriteConfig.bookingsCollectionId,
          [
            Query.equal('userId', user.userId),
            Query.orderDesc('$createdAt')
          ]
        );

        const bookingsWithEvents = await Promise.all(
          response.documents.map(async (booking) => {
            try {
              const eventData = await eventService.get(booking.eventId);
              
              if (!eventData) {
                throw new Error('Event not found');
              }

              return {
                $id: booking.$id,
                userId: booking.userId,
                eventId: booking.eventId,
                quantity: booking.quantity,
                totalAmount: booking.totalAmount,
                status: booking.status,
                createdAt: booking.$createdAt,
                event: {
                  title: eventData.title,
                  date: eventData.date,
                  time: eventData.time,
                  imageUrl: eventData.imageUrl,
                  restaurantName: eventData.restaurant?.name || eventData.restaurantName || 'Restaurant not available',
                  address: eventData.restaurant?.address || eventData.address || 'Address not available'
                }
              } as Booking;
            } catch (error) {
              console.error('Failed to fetch event details:', error);
              return {
                $id: booking.$id,
                userId: booking.userId,
                eventId: booking.eventId,
                quantity: booking.quantity,
                totalAmount: booking.totalAmount,
                status: booking.status,
                createdAt: booking.$createdAt,
                event: {
                  title: 'Event details not available',
                  date: '',
                  time: '',
                  imageUrl: '',
                  restaurantName: 'Restaurant not available',
                  address: 'Address not available'
                }
              } as Booking;
            }
          })
        );

        setBookings(bookingsWithEvents);
      } catch (error) {
        console.error('Failed to fetch bookings:', error);
        setError('Failed to load bookings');
      } finally {
        setLoading(false);
      }
    }

    fetchBookings();
  }, [user]);

  const handleBookingClick = (bookingId: string) => {
    navigate(`/bookings/${bookingId}`);
  };

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'attending':
        return 'bg-green-100 text-green-800';
      case 'cancelled':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  if (loading) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="animate-pulse">
          <div className="h-8 w-48 bg-gray-200 rounded mb-8"></div>
          <div className="space-y-6">
            {[1, 2, 3].map((i) => (
              <div key={i} className="h-32 bg-gray-200 rounded"></div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-red-50 text-red-700 p-4 rounded-md">
          {error}
        </div>
      </div>
    );
  }

  if (!bookings.length) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">My Bookings</h1>
        <Card className="p-6 text-center text-gray-500">
          You haven't made any bookings yet.
        </Card>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-3xl font-bold text-gray-900 mb-8">My Bookings</h1>
      <div className="space-y-6">
        {bookings.map((booking) => (
          <div 
            key={booking.$id}
            className="cursor-pointer hover:shadow-lg transition-shadow duration-200"
            onClick={() => handleBookingClick(booking.$id)}
          >
            <Card className="p-6">
              <div className="flex items-start space-x-6">
                <img
                  src={booking.event.imageUrl || 'https://via.placeholder.com/150'}
                  alt={booking.event.title}
                  className="w-32 h-32 object-cover rounded-lg"
                />
                <div className="flex-1">
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="text-xl font-semibold text-gray-900 mb-2">
                        {booking.event.title}
                      </h3>
                      <div className="text-gray-600 space-y-1">
                        <p>
                          <span className="font-medium">Restaurant:</span> {booking.event.restaurantName}
                        </p>
                        <p>
                          <span className="font-medium">Date:</span> {booking.event.date ? format(new Date(booking.event.date), 'MMMM d, yyyy') : 'Date not available'}
                        </p>
                        <p>
                          <span className="font-medium">Time:</span> {booking.event.time || 'Time not available'}
                        </p>
                        <p>
                          <span className="font-medium">Tickets:</span> {booking.quantity}
                        </p>
                        <p>
                          <span className="font-medium">Total:</span> ${booking.totalAmount?.toFixed(2)}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      <span className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusColor(booking.status)}`}>
                        {booking.status}
                      </span>
                      <ChevronRight className="w-5 h-5 text-gray-400" />
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
}