import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { account, databases } from '../../lib/appwrite';
import { Button } from '../../components/ui/Button';
import { appwriteConfig } from '../../config/appwrite';
import { Query } from 'appwrite';

export function VerifyEmailPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState<'verifying' | 'success' | 'error'>('verifying');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function verifyEmail() {
      try {
        const userId = searchParams.get('userId');
        const token = searchParams.get('token');

        if (!userId || !token) {
          throw new Error('Missing verification parameters. Please check your email link.');
        }

        console.log('Verifying email with token');
        
        // In a production app, you would verify this token against your database
        // Here, we're just marking the user as verified in Appwrite directly
        try {
          // Get the user to verify
          const response = await databases.listDocuments(
            appwriteConfig.databaseId,
            appwriteConfig.usersCollectionId,
            [Query.equal('userId', userId)]
          );

          if (response.documents.length === 0) {
            throw new Error('User not found');
          }
          
          const userDoc = response.documents[0];
          
          // Update the user document to mark it as verified
          await databases.updateDocument(
            appwriteConfig.databaseId,
            appwriteConfig.usersCollectionId,
            userDoc.$id,
            {
              isVerified: true,
            }
          );
          
          console.log('Email verification successful');
          setStatus('success');
        } catch (dbError) {
          console.error('Database error during verification:', dbError);
          throw new Error('Failed to verify your email. Please try again later.');
        }
      } catch (error) {
        console.error('Email verification failed:', error);
        setStatus('error');
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError('An unknown error occurred during verification');
        }
      }
    }

    verifyEmail();
  }, [searchParams]);

  const handleContinue = () => {
    navigate('/events');
  };

  const handleRetry = () => {
    window.location.reload();
  };

  if (status === 'verifying') {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-purple-50 to-indigo-50 p-4">
        <div className="max-w-md w-full space-y-8 bg-white p-8 rounded-xl shadow-lg text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500 mx-auto"></div>
          <h2 className="text-2xl font-bold text-gray-900">Verifying Your Email</h2>
          <p className="text-gray-600">Please wait while we verify your email address...</p>
        </div>
      </div>
    );
  }

  if (status === 'success') {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-purple-50 to-indigo-50 p-4">
        <div className="max-w-md w-full space-y-8 bg-white p-8 rounded-xl shadow-lg text-center">
          <div className="mx-auto h-16 w-16 flex items-center justify-center rounded-full bg-green-100">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
            </svg>
          </div>
          <h2 className="text-2xl font-bold text-gray-900">Email Verified!</h2>
          <p className="text-gray-600">Thank you for verifying your email address. Your account is now fully active.</p>
          <Button onClick={handleContinue} className="w-full mt-4">
            Continue to Try Everything
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-purple-50 to-indigo-50 p-4">
      <div className="max-w-md w-full space-y-8 bg-white p-8 rounded-xl shadow-lg text-center">
        <div className="mx-auto h-16 w-16 flex items-center justify-center rounded-full bg-red-100">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
        <h2 className="text-2xl font-bold text-gray-900">Verification Failed</h2>
        <p className="text-gray-600">
          {error || 'We encountered an issue while verifying your email. The verification link may have expired.'}
        </p>
        <div className="flex flex-col space-y-4 mt-6">
          <Button onClick={handleRetry} variant="outline">
            Try Again
          </Button>
          <Button onClick={() => navigate('/login')} variant="secondary">
            Return to Login
          </Button>
        </div>
      </div>
    </div>
  );
} 