import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { ScrollToTop } from './components/ScrollToTop';
import { routes } from './routes/index';
import { CheckoutSuccessPage } from './pages/CheckoutSuccessPage';
import { BookingConfirmationPage } from './pages/BookingConfirmationPage';
import { LoginPage } from './pages/LoginPage';
import { SignupPage } from './pages/SignupPage';
import { GoogleCallback } from './pages/auth/GoogleCallback';
import { useAuth } from './contexts/AuthContext';

// Home route component that handles auth status and redirects
function HomeRoute() {
  const { user, loading, handleGoogleCallback } = useAuth();
  const navigate = useNavigate();
  const [processingOAuth, setProcessingOAuth] = useState(false);

  // Check URL params for potential OAuth redirects
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const hasOAuthParams = urlParams.has('code') || urlParams.has('provider') || urlParams.has('userId');

    // If this might be an OAuth redirect
    if (hasOAuthParams && !processingOAuth) {
      setProcessingOAuth(true);
      console.log('Home: Detected potential OAuth redirect params:', Object.fromEntries(urlParams.entries()));
      
      // If this is a known OAuth response, handle it
      (async () => {
        try {
          console.log('Home: Attempting to handle OAuth callback');
          await handleGoogleCallback();
          console.log('Home: Successfully handled OAuth callback, redirecting to events');
          navigate('/events', { replace: true });
        } catch (error) {
          console.error('Home: Failed to handle OAuth callback:', error);
          navigate('/login', { replace: true });
        } finally {
          setProcessingOAuth(false);
        }
      })();
    }
  }, [navigate, handleGoogleCallback]);

  // Show loading indicator while checking auth or processing OAuth
  if (loading || processingOAuth) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="flex flex-col items-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500 mb-4"></div>
          <p className="text-gray-600">{processingOAuth ? 'Completing sign in...' : 'Loading...'}</p>
        </div>
      </div>
    );
  }

  // Regular homepage logic
  return routes.find(route => route.path === '/')?.element || <Navigate to="/events" />;
}

export function App() {
  return (
    <AuthProvider>
      <Router>
        <ScrollToTop />
        <div className="min-h-screen flex flex-col bg-gradient-to-b from-gray-50 to-white">
          <Header />
          <main className="flex-grow">
            <Routes>
              <Route path="/" element={<HomeRoute />} />
              {routes.filter(route => route.path !== '/').map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={route.element}
                />
              ))}
              <Route path="/checkout/success" element={<CheckoutSuccessPage />} />
              <Route path="/booking-confirmation" element={<BookingConfirmationPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/signup" element={<SignupPage />} />
              <Route path="/auth/callback/google" element={<GoogleCallback />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </AuthProvider>
  );
}