import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth as useAuthHook } from '../hooks/useAuth';
import { account, databases, ID } from '../lib/appwrite';
import { appwriteConfig } from '../config/appwrite';
import type { Models } from 'appwrite';
import { Query } from 'appwrite';
import { bookingService } from '../services/booking';

interface User {
  $id: string;
  userId: string;
  email: string;
  name: string;
  role: string;
  createdAt: string;
  updatedAt: string;
  phone?: string;
  city?: string;
  avatar?: string;
  restaurantId?: string | null;
  eventUpdates?: boolean;
  newEventsUpdate?: boolean;
}

interface AuthContextType {
  user: User | null;
  loading: boolean;
  error: string | null;
  setUser: (user: User | null) => void;
  signUp: (email: string, password: string, name: string) => Promise<User>;
  signInWithGoogle: () => Promise<void>;
  signInWithEmail: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  handleGoogleCallback: () => Promise<User | null>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const SESSION_CHECK_INTERVAL = 4 * 60 * 1000; // Check session every 4 minutes
const SESSION_EXPIRY_THRESHOLD = 10 * 60 * 1000; // Refresh if less than 10 minutes left

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [error, setError] = useState<string | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  // Function to fetch user document
  const fetchUserDocument = async (userId: string): Promise<User | null> => {
    try {
      const response = await databases.listDocuments(
        appwriteConfig.databaseId,
        appwriteConfig.usersCollectionId,
        [Query.equal('userId', userId)]
      );

      if (response.documents.length > 0) {
        const doc = response.documents[0];
        return {
          $id: doc.$id,
          userId: doc.userId,
          email: doc.email,
          name: doc.name,
          role: doc.role,
          createdAt: doc.createdAt,
          updatedAt: doc.updatedAt,
          phone: doc.phone,
          city: doc.city,
          avatar: doc.avatar,
          restaurantId: doc.restaurantId,
          eventUpdates: doc.eventUpdates,
          newEventsUpdate: doc.newEventsUpdate
        };
      }
      return null;
    } catch (error) {
      console.error('Failed to fetch user document:', error);
      return null;
    }
  };

  // Function to refresh user data
  const refreshUserData = async () => {
    try {
      const accountDetails = await account.get();
      const userDoc = await fetchUserDocument(accountDetails.$id);
      if (userDoc) {
        setUser(userDoc);
      }
    } catch (error) {
      console.error('Failed to refresh user data:', error);
    }
  };

  // Function to check and refresh session
  const checkAndRefreshSession = async () => {
    try {
      const session = await account.getSession('current');
      
      if (!session) {
        setUser(null);
        return;
      }

      // Check if session needs refresh (less than 10 minutes left)
      const expiryTime = new Date(session.expire).getTime();
      const currentTime = new Date().getTime();
      
      if (expiryTime - currentTime < SESSION_EXPIRY_THRESHOLD) {
        // For OAuth sessions, we'll redirect to re-authentication
        // For email sessions, we'll just refresh the user data
        // This ensures the session stays valid as long as possible
        if (session.provider === 'google') {
          signInWithGoogle();
        } else {
          await refreshUserData();
        }
      }
    } catch (error) {
      console.error('Session check failed:', error);
      // Only clear user if it's a session-related error
      if ((error as any)?.code === 401) {
        setUser(null);
      }
    }
  };

  // Initial auth check
  useEffect(() => {
    async function initializeAuth() {
      try {
        console.log('Initializing auth check');
        
        // Check for current session
        let session;
        try {
          session = await account.getSession('current');
          console.log('Auth check - Session exists:', !!session);
        } catch (sessionError) {
          console.log('Auth check - No valid session found');
          setLoading(false);
          return;
        }
        
        if (!session) {
          setLoading(false);
          return;
        }

        // Get account details
        let accountDetails;
        try {
          accountDetails = await account.get();
          console.log('Auth check - Account details:', accountDetails);
        } catch (accountError) {
          console.error('Auth check - Failed to get account details:', accountError);
          setLoading(false);
          return;
        }
        
        // Try to get user document
        const userDoc = await fetchUserDocument(accountDetails.$id);
        console.log('Auth check - User document:', userDoc);
        
        if (userDoc) {
          console.log('Auth check - Setting user from existing document');
          setUser(userDoc);
        } else {
          console.log('Auth check - No user document found, creating one');
          try {
            // This handles users coming back from OAuth without going through callback
            const newUser = await createUserDocument(accountDetails);
            setUser(newUser);
            console.log('Auth check - Created new user document for OAuth user');
          } catch (createError) {
            console.error('Auth check - Failed to create user document:', createError);
          }
        }
      } catch (error) {
        console.error('Failed to initialize auth:', error);
        setError('Failed to initialize auth');
      } finally {
        setLoading(false);
      }
    }

    initializeAuth();
  }, []);

  // Set up periodic session check
  useEffect(() => {
    if (!user) return;

    const intervalId = setInterval(checkAndRefreshSession, SESSION_CHECK_INTERVAL);
    
    return () => clearInterval(intervalId);
  }, [user]);

  // Add a function to associate guest bookings with a user account
  const associateGuestBookingsWithUser = async (userId: string) => {
    try {
      // Check if we have a booking ID in the URL
      const urlParams = new URLSearchParams(window.location.search);
      const bookingId = urlParams.get('booking_id');
      
      if (bookingId) {
        console.log('Attempting to associate guest booking with user:', { bookingId, userId });
        await bookingService.transferGuestBooking(bookingId, userId);
        console.log('Successfully transferred guest booking to user account');
        
        // Clean up URL
        const redirectUrl = urlParams.get('redirect') || '/';
        const newUrl = redirectUrl.includes('?') 
          ? `${redirectUrl}&transferred=true` 
          : `${redirectUrl}?transferred=true`;
        
        window.history.replaceState({}, '', newUrl);
      }
    } catch (error) {
      console.error('Failed to associate guest booking with user:', error);
      // We don't want to fail the sign-in process if this fails
    }
  };

  // Modify signInWithEmail method
  const signInWithEmail = async (email: string, password: string) => {
    try {
      setLoading(true);
      setError(null);
      
      // Create new email session with extended duration (30 days)
      await account.createEmailSession(email, password);
      const accountDetails = await account.get();
      const userDoc = await fetchUserDocument(accountDetails.$id);
      
      if (userDoc) {
        setUser(userDoc);
        // Associate any guest bookings with this user
        await associateGuestBookingsWithUser(accountDetails.$id);
      } else {
        // Create user document if it doesn't exist
        try {
          const now = new Date().toISOString();
          const newUserDoc = await databases.createDocument(
            appwriteConfig.databaseId,
            appwriteConfig.usersCollectionId,
            ID.unique(),
            {
              userId: accountDetails.$id,
              email: accountDetails.email,
              name: accountDetails.name,
              role: 'user',
              createdAt: now,
              updatedAt: now,
              phone: '',
              city: '',
              avatar: '',
              restaurantId: null
            }
          );
          setUser({
            $id: newUserDoc.$id,
            userId: accountDetails.$id,
            email: accountDetails.email,
            name: accountDetails.name,
            role: 'user',
            createdAt: now,
            updatedAt: now,
            phone: '',
            city: '',
            avatar: '',
            restaurantId: null
          } as User);
          
          // Associate any guest bookings with this user
          await associateGuestBookingsWithUser(accountDetails.$id);
        } catch (createError) {
          console.error('Failed to create user document:', createError);
          throw new Error('Failed to complete sign in. Please try again.');
        }
      }
    } catch (error: any) {
      console.error('Email sign in error:', error);
      
      // Handle specific Appwrite error codes
      if (error.code === 401 || error.type === 'user_invalid_credentials') {
        // Check if the user exists by attempting to create a session
        try {
          await account.createEmailSession(email, password);
          throw new Error('Invalid password. Please try again.');
        } catch (createError: any) {
          if (createError.code === 401) {
            throw new Error('No account found with this email. Please sign up first.');
          }
          throw createError;
        }
      } else if (error.code === 429) {
        throw new Error('Too many attempts. Please try again later');
      } else if (error.type === 'user_not_found') {
        throw new Error('No account found with this email. Please sign up first.');
      } else if (!navigator.onLine) {
        throw new Error('Network error. Please check your connection');
      } else {
        throw new Error('Failed to sign in. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const signInWithGoogle = async () => {
    try {
      setLoading(true);
      setError(null);
      
      console.log('Initiating Google OAuth session');
      
      // Store any booking ID or redirect info in localStorage before redirecting
      const urlParams = new URLSearchParams(window.location.search);
      const bookingId = urlParams.get('booking_id') || localStorage.getItem('bookingId');
      const redirect = urlParams.get('redirect');
      const returnTo = urlParams.get('returnTo');
      
      if (bookingId) {
        localStorage.setItem('postGoogleAuthBookingId', bookingId);
      }
      
      if (redirect) {
        localStorage.setItem('postGoogleAuthRedirect', redirect);
      }
      
      if (returnTo) {
        localStorage.setItem('postGoogleAuthReturnTo', returnTo);
      }

      // Check if we already have a session
      try {
        const existingSession = await account.getSession('current');
        if (existingSession) {
          console.log('Existing session found, signing out first');
          // If we have an existing session, sign out first to avoid conflicts
          await account.deleteSession('current');
        }
      } catch (sessionError) {
        console.log('No existing session found, proceeding with sign in');
        // No session exists, which is fine
      }
      
      // Use Appwrite's built-in OAuth2 session creation
      // This will handle both new users (sign up) and existing users (sign in)
      await account.createOAuth2Session(
        'google',
        `${window.location.origin}/auth/callback/google`, // Success URL
        `${window.location.origin}/login?error=auth_failed` // Failure URL
      );
      
      // This code will not execute due to the redirect
      return;
    } catch (error: any) {
      console.error('Google sign in error:', error);
      setLoading(false);
      
      // Handle specific error cases
      if (error.code === 401) {
        throw new Error('Google authentication failed. Please try again.');
      } else if (error.code === 409 || error.type === 'user_already_exists') {
        // User already exists, try to sign in
        console.log('User already exists, attempting to sign in');
        try {
          // First sign out to clear any existing session
          try {
            await account.deleteSession('current');
          } catch (signOutError) {
            console.log('No session to delete');
          }
          
          // Then create a new OAuth session
          await account.createOAuth2Session(
            'google',
            `${window.location.origin}/auth/callback/google`,
            `${window.location.origin}/login?error=auth_failed`
          );
          return;
        } catch (retryError) {
          console.error('Failed to sign in with Google:', retryError);
        }
        throw new Error('Account already exists. Please try signing in instead.');
      } else if (error.code === 429) {
        throw new Error('Too many attempts. Please try again later.');
      } else if (!navigator.onLine) {
        throw new Error('Network error. Please check your connection.');
      } else {
        throw new Error('Failed to sign in with Google. Please try again.');
      }
    }
  };

  // Function to create user document
  const createUserDocument = async (accountDetails: Models.User<Models.Preferences>): Promise<User> => {
    try {
      console.log('Creating user document for:', accountDetails);
      
      // First check if a document already exists for this user
      const existingDocs = await databases.listDocuments(
        appwriteConfig.databaseId,
        appwriteConfig.usersCollectionId,
        [Query.equal('userId', accountDetails.$id)]
      );
      
      if (existingDocs.documents.length > 0) {
        console.log('User document already exists, returning existing document');
        const doc = existingDocs.documents[0];
        return {
          $id: doc.$id,
          userId: doc.userId,
          email: doc.email,
          name: doc.name,
          role: doc.role,
          createdAt: doc.createdAt,
          updatedAt: doc.updatedAt,
          phone: doc.phone || '',
          city: doc.city || '',
          avatar: doc.avatar || '',
          restaurantId: doc.restaurantId || null,
          eventUpdates: doc.eventUpdates || true,
          newEventsUpdate: doc.newEventsUpdate || true
        };
      }
      
      const now = new Date().toISOString();
      
      // Prepare user data
      const userData = {
        userId: accountDetails.$id,
        email: accountDetails.email,
        name: accountDetails.name || accountDetails.email?.split('@')[0] || 'User',
        role: 'user',
        createdAt: now,
        updatedAt: now,
        phone: '',
        city: '',
        avatar: accountDetails.prefs?.avatar || '',
        restaurantId: null,
        eventUpdates: true,
        newEventsUpdate: true
      };

      console.log('Prepared user data:', userData);

      // Create the document
      const newUserDoc = await databases.createDocument(
        appwriteConfig.databaseId,
        appwriteConfig.usersCollectionId,
        ID.unique(),
        userData
      );

      console.log('Created user document:', newUserDoc);

      // Return user object
      return {
        $id: newUserDoc.$id,
        userId: newUserDoc.userId,
        email: newUserDoc.email,
        name: newUserDoc.name,
        role: newUserDoc.role,
        createdAt: newUserDoc.createdAt,
        updatedAt: newUserDoc.updatedAt,
        phone: newUserDoc.phone || '',
        city: newUserDoc.city || '',
        avatar: newUserDoc.avatar || '',
        restaurantId: newUserDoc.restaurantId || null,
        eventUpdates: newUserDoc.eventUpdates || true,
        newEventsUpdate: newUserDoc.newEventsUpdate || true
      };
    } catch (error) {
      console.error('Failed to create user document:', error);
      if (error instanceof Error) {
        throw new Error(`Failed to create user document: ${error.message}`);
      } else {
        throw new Error('Failed to create user document: Unknown error');
      }
    }
  };

  // Modify handleGoogleCallback method
  const handleGoogleCallback = async () => {
    try {
      console.log('Starting Google callback handler');

      // Check if we have an active session
      let session;
      try {
        session = await account.getSession('current');
        console.log('Google callback - Found active session:', session);
      } catch (sessionError) {
        console.error('Google callback - No valid session found:', sessionError);
        throw new Error('No active session found. Please try signing in again.');
      }

      // Get account details
      let accountDetails;
      try {
        accountDetails = await account.get();
        console.log('Google callback - Got account details:', accountDetails);
      } catch (accountError) {
        console.error('Google callback - Failed to get account details:', accountError);
        throw new Error('Failed to get account details. Please try signing in again.');
      }
      
      // Check if a user document already exists for this account
      console.log('Google callback - Checking for existing user document');
      let userDoc = await fetchUserDocument(accountDetails.$id);
      
      if (userDoc) {
        // User document exists - this is an existing user signing in
        console.log('Google callback - Existing user signing in');
        
        // Update the user document with any new information from Google
        try {
          const now = new Date().toISOString();
          await databases.updateDocument(
            appwriteConfig.databaseId,
            appwriteConfig.usersCollectionId,
            userDoc.$id,
            {
              name: accountDetails.name || userDoc.name,
              email: accountDetails.email,
              avatar: accountDetails.prefs?.avatar || userDoc.avatar,
              updatedAt: now
            }
          );
          
          // Refresh the user document
          const refreshedDoc = await fetchUserDocument(accountDetails.$id);
          if (refreshedDoc) {
            userDoc = refreshedDoc;
          }
          console.log('Google callback - Updated existing user document');
        } catch (updateError) {
          console.error('Google callback - Failed to update user document:', updateError);
          // Continue with the existing document even if update fails
        }
      } else {
        // No user document - this is a new user signing up
        console.log('Google callback - New user signing up with Google');
        try {
          userDoc = await createUserDocument(accountDetails);
          console.log('Google callback - Created new user document:', userDoc);
        } catch (createError: any) {
          if (createError.message?.includes('already exists')) {
            // If the user already exists, try to fetch their document again
            console.log('Google callback - User already exists, fetching document');
            userDoc = await fetchUserDocument(accountDetails.$id);
            if (!userDoc) {
              throw new Error('User exists but document not found');
            }
          } else {
            throw createError;
          }
        }
      }
      
      // Associate any guest bookings with this user
      await associateGuestBookingsWithUser(accountDetails.$id);
      
      // Set user in context
      setUser(userDoc);
      console.log('Google callback - Successfully set user:', userDoc);

      return userDoc;
    } catch (error: any) {
      console.error('Google callback - Unhandled error:', error);
      
      // Handle specific error codes
      if (error.code === 401) {
        throw new Error('Authentication failed. Please try signing in again.');
      } else if (error.code === 409 || error.type === 'user_already_exists') {
        // User already exists, try to sign in
        console.log('Google callback - User already exists, attempting to sign in');
        try {
          // Get the existing user's details
          const accountDetails = await account.get();
          const userDoc = await fetchUserDocument(accountDetails.$id);
          
          if (userDoc) {
            setUser(userDoc);
            await associateGuestBookingsWithUser(accountDetails.$id);
            return userDoc;
          } else {
            throw new Error('User exists but document not found');
          }
        } catch (retryError) {
          console.error('Google callback - Failed to recover from 409 error:', retryError);
          throw new Error('Account already exists but failed to sign in. Please try again.');
        }
      } else if (error.message?.includes('Failed to create user document')) {
        throw error; // Re-throw user document creation errors
      } else {
        throw new Error(`Failed to complete sign in: ${error.message || 'Unknown error'}`);
      }
    }
  };

  const signOut = async () => {
    try {
      await account.deleteSession('current');
      setUser(null);
      setError(null);
    } catch (error) {
      console.error('Sign out error:', error);
      throw error;
    }
  };

  // Add signUp function
  const signUp = async (email: string, password: string, name: string): Promise<User> => {
    try {
      setLoading(true);
      setError(null);
      
      // Create Appwrite account first
      const accountResponse = await account.create(
        ID.unique(),
        email,
        password,
        name
      );
      
      console.log('Account created:', accountResponse);

      // Create session after account creation
      try {
        await account.createEmailSession(email, password);
      } catch (sessionError: any) {
        console.error('Session creation error:', sessionError);
        // If session creation fails, we still want to create the user document
        // The user can sign in later
      }

      // Create user document
      const now = new Date().toISOString();
      const userData = {
        userId: accountResponse.$id,
        email,
        name,
        role: 'user',
        createdAt: now,
        updatedAt: now,
        phone: '',
        city: '',
        avatar: '',
        restaurantId: null,
        eventUpdates: true,
        newEventsUpdate: true
      };

      const newUserDoc = await databases.createDocument(
        appwriteConfig.databaseId,
        appwriteConfig.usersCollectionId,
        ID.unique(),
        userData
      );

      console.log('User document created:', newUserDoc);

      // Associate any guest bookings
      await associateGuestBookingsWithUser(accountResponse.$id);

      // Return user object
      const user = {
        $id: newUserDoc.$id,
        userId: accountResponse.$id,
        email,
        name,
        role: 'user',
        createdAt: now,
        updatedAt: now,
        phone: '',
        city: '',
        avatar: '',
        restaurantId: null,
        eventUpdates: true,
        newEventsUpdate: true
      } as User;
      
      setUser(user);
      return user;
    } catch (error: any) {
      console.error('Signup error:', error);
      
      // Handle specific Appwrite error codes
      if (error.code === 409) {
        // User already exists, try to sign in instead
        console.log('User already exists, attempting to sign in instead');
        try {
          // Try to sign in with the provided credentials
          await account.createEmailSession(email, password);
          
          // Get account details
          const accountDetails = await account.get();
          
          // Get user document
          const userDoc = await fetchUserDocument(accountDetails.$id);
          
          if (userDoc) {
            setUser(userDoc);
            // Associate any guest bookings
            await associateGuestBookingsWithUser(accountDetails.$id);
            return userDoc;
          } else {
            throw new Error('User account exists but user document not found');
          }
        } catch (signInError: any) {
          console.error('Failed to sign in existing user:', signInError);
          throw new Error('Account with this email already exists. Please sign in instead.');
        }
      } else if (error.code === 400) {
        throw new Error('Invalid email or password format');
      } else if (error.code === 401) {
        throw new Error('Please sign in to create an account');
      } else if (!navigator.onLine) {
        throw new Error('Network error. Please check your connection');
      } else {
        throw new Error(error.message || 'Failed to create account');
      }
    } finally {
      setLoading(false);
    }
  };

  const value = {
    user,
    loading,
    error,
    setUser,
    signUp,
    signInWithEmail,
    signInWithGoogle,
    signOut,
    handleGoogleCallback
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}