import React, { useEffect, useState, useRef } from 'react';
import { useSearchParams, Navigate } from 'react-router-dom';
import { CheckCircle } from 'lucide-react';
import { Button } from '../components/ui/Button';
import { useAuth } from '../contexts/AuthContext';
import { bookingService } from '../services/booking';
import { eventService } from '../services/database';

export function CheckoutSuccessPage() {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [bookingId, setBookingId] = useState<string | null>(null);
  
  // Use a ref to track if booking process has started
  const processStarted = useRef(false);

  useEffect(() => {
    // Only run this once per component instance
    if (!sessionId || processStarted.current) return;
    
    // Mark that we've started processing
    processStarted.current = true;
    
    async function createBooking() {
      try {
        setLoading(true);
        console.log('Verifying payment with backend...');
        
        // Force sessionId to be treated as string since we've already checked it's not null
        const sessionIdString = sessionId as string;
        
        // 1. Verify the payment
        const response = await fetch('/.netlify/functions/verify-payment', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ sessionId: sessionIdString })
        });

        const { success, data } = await response.json();
        
        if (!success) {
          throw new Error('Payment verification failed');
        }

        console.log('Payment verified, booking data:', data);
        
        // 2. Create the booking
        console.log('Creating booking...');
        let booking;
        
        // For logged-in users, use the client-side service
        booking = await bookingService.createBooking(
          data.eventId,
          data.userId,
          data.quantity,
          data.amount / 100,
          sessionIdString
        );

        setBookingId(booking.$id);
        console.log('Booking created with ID:', booking.$id);

        // 3. Update event registration count
        console.log('Updating event registration count...');
        await eventService.updateRegistered(data.eventId, data.quantity);

        // 4. Send email notifications
        console.log('Sending notifications with data:', data);
        const emailResponse = await fetch('/.netlify/functions/sendPurchaseNotifications', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            ...data,
            bookingId: booking.$id // Include the booking ID in notification data
          })
        });
        
        const emailResult = await emailResponse.json();
        console.log('Email sending result:', emailResult);

        // 5. Redirect to booking confirmation
        window.location.href = `/booking-confirmation?booking_id=${booking.$id}`;
      } catch (error) {
        console.error('Error in checkout process:', error);
        setError('Failed to process booking. Please contact support.');
      } finally {
        setLoading(false);
      }
    }

    createBooking();
  }, [sessionId, user]); // Only depend on sessionId and user, not on any state variables that change during the process

  if (!sessionId) {
    return <Navigate to="/events" replace />;
  }

  if (loading) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center p-4">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#29303D] mb-4"></div>
        <h2 className="text-xl font-semibold text-gray-900 mb-2">Processing Your Order</h2>
        <p className="text-gray-600 text-center">
          Please wait while we confirm your payment and create your booking...
        </p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-2xl mx-auto px-4 py-8 text-center">
        <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-6">
          {error}
        </div>
        <Button onClick={() => window.location.href = '/events'}>
          Return to Events
        </Button>
      </div>
    );
  }

  return null; // The page will redirect before reaching this point
} 