import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from '../ui/Card';
import { Calendar, MapPin, Users } from 'lucide-react';
import { formatDate, formatEventTime } from '../../utils/date';
import type { Event } from '../../types/database';
import { Button } from '../ui/Button';
import { cn } from '../../lib/utils';

interface EventCardProps {
  event: Event;
  compact?: boolean;
}

export function EventCard({ event, compact = false }: EventCardProps) {
  // Check if event is in the past
  const isPastEvent = new Date(`${event.date}T${event.time}`) < new Date();
  const isSoldOut = event.registered >= event.capacity;
  const spotsLeft = event.capacity - event.registered;

  return (
    <Link 
      to={`/events/${event.$id}`}
      className={cn(
        "block",
        (isPastEvent || isSoldOut) && "pointer-events-none opacity-75"
      )}
    >
      <Card className="h-full overflow-hidden hover:shadow-lg transition-shadow flex flex-col bg-[#29303D]/[0.02]">
        <div className="relative h-48 md:h-56 lg:h-48 xl:h-56">
          <img 
            src={event.imageUrl} 
            alt={event.title}
            className="w-full h-full object-cover"
          />
          {/* Status badges */}
          {isPastEvent ? (
            <div className="absolute top-4 right-4 bg-gray-500 text-white px-3 py-1 rounded-full text-sm font-medium shadow-md">
              Past Event
            </div>
          ) : isSoldOut ? (
            <div className="absolute top-4 right-4 bg-red-500 text-white px-3 py-1 rounded-full text-sm font-medium shadow-md">
              Sold Out
            </div>
          ) : spotsLeft <= 5 ? (
            <div className="absolute top-4 right-4 bg-[#29303D] text-white px-3 py-1 rounded-full text-sm font-medium shadow-md">
              Only {spotsLeft} spots left!
            </div>
          ) : null}
        </div>
        <div className="p-6 flex-grow flex flex-col">
          <div className="mb-4">
            <div className="flex items-center justify-between mb-2">
              <h3 className="text-xl font-bold text-[#29303D] line-clamp-2">
                {event.title}
              </h3>
              <span className="text-2xl font-bold text-[#29303D] ml-4 whitespace-nowrap">
                ${event.price}
              </span>
            </div>
            {!compact && (
              <p className="text-[#29303D]/70 text-sm line-clamp-2 mb-4">
                {event.description}
              </p>
            )}
          </div>
          <div className="flex-grow">
            <div className="space-y-2 text-sm text-[#29303D]/70">
              <div className="flex items-center">
                <Calendar className="h-4 w-4 mr-2 flex-shrink-0 text-[#29303D]" />
                <span>{formatDate(event.date)} at {formatEventTime(event.time)}</span>
              </div>
              <div className="flex items-center">
                <MapPin className="h-4 w-4 mr-2 flex-shrink-0 text-[#29303D]" />
                <span className="line-clamp-1">{event.restaurant?.name}</span>
              </div>
              <div className="flex items-center">
                <Users className="h-4 w-4 mr-2 flex-shrink-0 text-[#29303D]" />
                {isPastEvent ? (
                  <span className="text-gray-500">Event has ended</span>
                ) : isSoldOut ? (
                  <span className="text-red-500 font-medium">Sold Out</span>
                ) : (
                  <span className={spotsLeft <= 5 ? 'text-[#29303D] font-medium' : ''}>
                    {spotsLeft} spots left
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="mt-4">
            <Button 
              className={cn(
                "w-full text-white shadow-md font-semibold text-lg py-3",
                isPastEvent 
                  ? "bg-gray-400 cursor-not-allowed"
                  : isSoldOut
                    ? "bg-red-500 cursor-not-allowed"
                    : "bg-[#29303D] hover:bg-[#29303D]/90"
              )}
              disabled={isPastEvent || isSoldOut}
            >
              {isPastEvent 
                ? "Event Ended"
                : isSoldOut
                  ? "Sold Out"
                  : "Book Now →"}
            </Button>
          </div>
        </div>
      </Card>
    </Link>
  );
}