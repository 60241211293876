import { Event } from '../types';

interface EventUpdatePayload {
  userName: string;
  userEmail: string;
  event: Event;
  updateType: 'change' | 'reminder' | 'cancellation';
  changes?: {
    field: string;
    oldValue: string;
    newValue: string;
  }[];
}

interface NewEventsPayload {
  userName: string;
  userEmail: string;
  events: Event[];
  city: string;
}

class EmailService {
  private async sendEmail(endpoint: string, payload: any) {
    try {
      const response = await fetch(`/.netlify/functions/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Failed to send email: ${response.statusText}`);
      }

      return await response.json();
    } catch (error) {
      console.error(`Failed to send email to ${endpoint}:`, error);
      throw error;
    }
  }

  async sendEventUpdate(payload: EventUpdatePayload) {
    return this.sendEmail('send-event-update', payload);
  }

  async sendNewEventsNotification(payload: NewEventsPayload) {
    return this.sendEmail('send-new-events', payload);
  }

  async sendEventReminder(userName: string, userEmail: string, event: Event) {
    return this.sendEventUpdate({
      userName,
      userEmail,
      event,
      updateType: 'reminder'
    });
  }

  async sendEventChanges(userName: string, userEmail: string, event: Event, changes: { field: string; oldValue: string; newValue: string; }[]) {
    return this.sendEventUpdate({
      userName,
      userEmail,
      event,
      updateType: 'change',
      changes
    });
  }

  async sendEventCancellation(userName: string, userEmail: string, event: Event) {
    return this.sendEventUpdate({
      userName,
      userEmail,
      event,
      updateType: 'cancellation'
    });
  }
}

export const emailService = new EmailService(); 