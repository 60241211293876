import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Link } from 'react-router-dom';

export function HeroSection() {
  const { user } = useAuth();
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  
  const profiles = [
    'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    'https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
  ];

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('form-name', 'waitlist');
      formData.append('email', email);

      await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(formData as any).toString()
      });

      setSubmitted(true);
      setEmail('');
    } catch (error) {
      console.error('Form submission error:', error);
    }
  };

  return (
    <section className="relative min-h-[90vh] sm:min-h-screen flex items-center">
      <div className="absolute inset-0">
        <img
          src="https://images.unsplash.com/photo-1552566626-52f8b828add9?ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80"
          alt="Luxury dining experience"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black/75" />
      </div>
      
      <div className="relative w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 sm:py-32">
        <div className="max-w-3xl mx-auto text-center">
          <div id="hero-content">
            <span className="text-white text-base sm:text-lg font-medium mb-3 sm:mb-4 block">
              Canada's Most Exclusive Dining Experience
            </span>
            <h1 className="text-3xl sm:text-5xl md:text-7xl font-bold text-white mb-4 sm:mb-6 leading-tight">
              Experience Every Dish on the Menu
            </h1>
            <p className="text-lg sm:text-xl md:text-2xl text-white mb-8 sm:mb-12 leading-relaxed">
              Join intimate dining events where you'll taste every dish, meet amazing people, 
              and experience Canada's finest restaurants like never before.
            </p>
          </div>

          {user ? (
            <div className="max-w-xl mx-auto">
              <Link 
                to="/events"
                className="inline-block bg-white text-black border-2 border-white px-8 py-4 rounded-full text-lg font-semibold hover:bg-white/90 transition-colors"
              >
                Explore Events
              </Link>
            </div>
          ) : (
            <form 
              name="waitlist"
              method="POST"
              data-netlify="true"
              netlify-honeypot="bot-field"
              onSubmit={handleSubmit} 
              className="max-w-xl mx-auto"
            >
              <input type="hidden" name="form-name" value="waitlist" />
              <div hidden>
                <input name="bot-field" />
              </div>

              {submitted ? (
                <div className="bg-white/20 backdrop-blur-sm text-white border-2 border-white/20 px-6 sm:px-8 py-3 sm:py-4 rounded-full text-base sm:text-lg text-center">
                  Thanks for joining! We'll be in touch.
                </div>
              ) : (
                <>
                  <div className="flex flex-col sm:flex-row gap-3 sm:gap-2">
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      className="flex-1 bg-white/20 backdrop-blur-sm text-white border-2 border-white/20 px-6 sm:px-8 py-3 sm:py-4 rounded-full text-base sm:text-lg placeholder:text-white/70 focus:outline-none focus:border-white/40"
                    />
                    <button 
                      type="submit" 
                      className="bg-white text-black border-2 border-white px-6 sm:px-8 py-3 sm:py-4 rounded-full text-base sm:text-lg font-semibold hover:bg-white/90 transition-colors whitespace-nowrap"
                    >
                      Join Waitlist
                    </button>
                  </div>
                  <div className="mt-3 flex items-center justify-center gap-2">
                    <div className="w-2 h-2 bg-green-400 rounded-full animate-pulse"/>
                    <p className="text-white/90 text-xs sm:text-sm font-medium">
                      Limited Seats Available • Our Last Event Sold Out Within Hours
                    </p>
                  </div>
                </>
              )}
            </form>
          )}

          <div className="mt-8 sm:mt-12 flex flex-col sm:flex-row items-center justify-center gap-4 sm:gap-8">
            <div className="flex -space-x-4">
              {profiles.map((profile, i) => (
                <img
                  key={i}
                  src={profile}
                  alt="Member"
                  className="w-8 sm:w-10 h-8 sm:h-10 rounded-full border-2 border-white object-cover"
                />
              ))}
            </div>
            <div className="text-white text-xs sm:text-sm text-center sm:text-left">
              Join 500+ food enthusiasts already experiencing Canada's best restaurants
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}