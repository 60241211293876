import { databases, DATABASE_ID, COLLECTIONS, ID, Query } from '../lib/appwrite';
import type { Restaurant, Event, Booking } from '../types/database';
import { emailService } from './email';

// Restaurant Services
export const restaurantService = {
  // List all restaurants with optional filters
  list: async (filters?: { city?: string; cuisine?: string }) => {
    const queries = [Query.equal('status', ['approved'])];
    
    if (filters?.city) {
      queries.push(Query.equal('city', filters.city));
    }
    
    if (filters?.cuisine) {
      queries.push(Query.equal('cuisine', filters.cuisine));
    }

    queries.push(Query.orderDesc('rating'));

    console.log('Fetching restaurants with:', {
      databaseId: DATABASE_ID,
      collectionId: COLLECTIONS.RESTAURANTS,
      queries
    });

    const response = await databases.listDocuments(
      DATABASE_ID,
      COLLECTIONS.RESTAURANTS,
      queries
    );

    return response.documents as Restaurant[];
  },

  // Get a single restaurant by restaurantId
  get: async (restaurantId: string) => {
    if (!restaurantId) {
      console.warn('No restaurant ID provided');
      return null;
    }

    try {
      const response = await databases.listDocuments(
        DATABASE_ID,
        COLLECTIONS.RESTAURANTS,
        [Query.equal('restaurantId', restaurantId)]
      );

      if (response.documents.length === 0) {
        console.warn('No restaurant found with restaurantId:', restaurantId);
        return null;
      }

      return response.documents[0] as Restaurant;
    } catch (error) {
      console.error('Failed to fetch restaurant:', {
        error,
        restaurantId,
        collectionId: COLLECTIONS.RESTAURANTS
      });
      throw error;
    }
  }
};

// Event Services
export const eventService = {
  // List all events with optional filters
  list: async () => {
    try {
      console.log('Fetching events from collection:', COLLECTIONS.EVENTS);
      
      // Get current date-time in ISO format
      const now = new Date().toISOString();
      
      const response = await databases.listDocuments(
        DATABASE_ID,
        COLLECTIONS.EVENTS,
        [
          Query.equal('status', 'published'),
          Query.greaterThan('date', now),
          Query.orderAsc('date'),
          Query.limit(100)
        ]
      );

      console.log('Events response:', response);
      return response.documents as Event[];
    } catch (error) {
      console.error('Failed to fetch events:', error);
      throw error;
    }
  },

  // Get a single event
  get: async (id: string) => {
    try {
      const event = await databases.getDocument(
        DATABASE_ID,
        COLLECTIONS.EVENTS,
        id
      ) as Event;

      // Fetch restaurant details and add them to the event
      if (event && event.restaurantId) {
        try {
          console.log('Fetching restaurant details for event:', id);
          const restaurant = await restaurantService.get(event.restaurantId);
          
          if (restaurant) {
            console.log('Attaching restaurant details to event');
            // Create a restaurant field on the event object
            event.restaurant = {
              name: restaurant.name,
              address: restaurant.address,
              city: restaurant.city,
              phone: restaurant.phone,
              website: restaurant.website
            };
          } else {
            console.warn('Restaurant not found for ID:', event.restaurantId);
            // Provide default restaurant information
            event.restaurant = {
              name: event.restaurantName || 'Restaurant not available',
              address: event.address || 'Address not available'
            };
          }
        } catch (restaurantError) {
          console.error('Error fetching restaurant details:', restaurantError);
          // Don't fail the event request if restaurant details can't be loaded
        }
      }

      return event;
    } catch (error) {
      console.error('Event fetch error:', error);
      return null;
    }
  },

  // Update event and notify attendees if needed
  update: async (eventId: string, updates: Partial<Event>) => {
    try {
      // Get current event to compare changes
      const currentEvent = await databases.getDocument(
        DATABASE_ID,
        COLLECTIONS.EVENTS,
        eventId
      ) as Event;

      // Update the event
      const updatedEvent = await databases.updateDocument(
        DATABASE_ID,
        COLLECTIONS.EVENTS,
        eventId,
        {
          ...updates,
          updatedAt: new Date().toISOString()
        }
      ) as Event;

      // Get list of attendees who want event updates
      const attendeesResponse = await databases.listDocuments(
        DATABASE_ID,
        COLLECTIONS.USERS,
        [
          Query.equal('userId', currentEvent.attendees || []),
          Query.equal('eventUpdates', true)
        ]
      );

      // Prepare changes for email
      const changes = Object.entries(updates)
        .filter(([key]) => ['date', 'time', 'restaurantName'].includes(key))
        .map(([field, newValue]) => ({
          field,
          oldValue: String(currentEvent[field as keyof Event]),
          newValue: String(newValue)
        }));

      // Send notifications to attendees
      if (changes.length > 0) {
        await Promise.all(attendeesResponse.documents.map(user => 
          emailService.sendEventChanges(
            user.name,
            user.email,
            updatedEvent,
            changes
          )
        ));
      }

      return updatedEvent;
    } catch (error) {
      console.error('Failed to update event:', error);
      throw error;
    }
  },

  // Create new event and queue for notification
  create: async (eventData: Partial<Event>) => {
    try {
      const now = new Date().toISOString();
      const newEvent = await databases.createDocument(
        DATABASE_ID,
        COLLECTIONS.EVENTS,
        ID.unique(),
        {
          ...eventData,
          createdAt: now,
          updatedAt: now,
          registeredCount: 0,
          waitlistCount: 0,
          status: 'upcoming',
          notificationQueued: true // Mark for batch processing
        }
      ) as Event;

      return newEvent;
    } catch (error) {
      console.error('Failed to create event:', error);
      throw error;
    }
  },

  // Cancel event and notify attendees
  cancel: async (eventId: string) => {
    try {
      const event = await databases.getDocument(
        DATABASE_ID,
        COLLECTIONS.EVENTS,
        eventId
      ) as Event;

      const updatedEvent = await databases.updateDocument(
        DATABASE_ID,
        COLLECTIONS.EVENTS,
        eventId,
        {
          status: 'cancelled',
          updatedAt: new Date().toISOString()
        }
      ) as Event;

      // Get attendees who want event updates
      const attendeesResponse = await databases.listDocuments(
        DATABASE_ID,
        COLLECTIONS.USERS,
        [
          Query.equal('userId', event.attendees || []),
          Query.equal('eventUpdates', true)
        ]
      );

      // Send cancellation notifications
      await Promise.all(
        attendeesResponse.documents.map(user =>
          emailService.sendEventCancellation(
            user.name,
            user.email,
            updatedEvent
          )
        )
      );

      return updatedEvent;
    } catch (error) {
      console.error('Failed to cancel event:', error);
      throw error;
    }
  },

  // Send reminders for upcoming events
  sendReminders: async () => {
    try {
      const now = new Date();
      const currentHour = now.getHours();
      
      // Morning run (9 AM) - send day-of reminders
      // Evening run (9 PM) - send next-day reminders
      const isEveningRun = currentHour >= 20;
      
      const targetDate = new Date();
      if (isEveningRun) {
        // For evening run, get tomorrow's events
        targetDate.setDate(targetDate.getDate() + 1);
      }
      const targetDateStr = targetDate.toISOString().split('T')[0];

      const eventsResponse = await databases.listDocuments(
        DATABASE_ID,
        COLLECTIONS.EVENTS,
        [
          Query.equal('date', targetDateStr),
          Query.equal('status', 'upcoming')
        ]
      );

      // For each event, get attendees who want updates
      await Promise.all(
        (eventsResponse.documents as Event[]).map(async (event) => {
          // Check if we've already sent reminders for this time slot
          const reminderKey = `${event.$id}_reminder_${isEveningRun ? 'next_day' : 'day_of'}`;
          const lastReminderSent = event.lastReminderSent?.[reminderKey];
          
          // Only send if we haven't sent a reminder in the last 12 hours
          if (lastReminderSent && 
              (new Date().getTime() - new Date(lastReminderSent).getTime()) < 12 * 60 * 60 * 1000) {
            console.log(`Skipping reminder for event ${event.$id} - already sent recently`);
            return;
          }

          const attendeesResponse = await databases.listDocuments(
            DATABASE_ID,
            COLLECTIONS.USERS,
            [
              Query.equal('userId', event.attendees || []),
              Query.equal('eventUpdates', true)
            ]
          );

          // Send reminders to each attendee
          await Promise.all(
            attendeesResponse.documents.map(user =>
              emailService.sendEventReminder(
                user.name,
                user.email,
                event
              )
            )
          );

          // Update the last reminder sent timestamp
          await databases.updateDocument(
            DATABASE_ID,
            COLLECTIONS.EVENTS,
            event.$id,
            {
              [`lastReminderSent.${reminderKey}`]: new Date().toISOString(),
              updatedAt: new Date().toISOString()
            }
          );
        })
      );
    } catch (error) {
      console.error('Failed to send event reminders:', error);
      throw error;
    }
  },

  listByRestaurant: async (restaurantId: string) => {
    console.log('listByRestaurant called with:', restaurantId);
    
    try {
      const queries = [
        Query.equal('restaurantId', restaurantId), // Use restaurantId directly
        Query.equal('status', 'published'),
        Query.greaterThanEqual('date', new Date().toISOString().split('T')[0]),
        Query.orderAsc('date')
      ];

      console.log('Fetching events with queries:', {
        restaurantId,
        queries: queries.map(q => q.toString())
      });

      const response = await databases.listDocuments(
        DATABASE_ID,
        COLLECTIONS.EVENTS,
        queries
      );

      console.log('Restaurant events response:', {
        total: response.total,
        documents: response.documents
      });

      return response.documents as Event[];
    } catch (error) {
      console.error('Failed to fetch restaurant events:', {
        error,
        restaurantId,
        collectionId: COLLECTIONS.EVENTS
      });
      throw error;
    }
  },

  updateRegistered: async (eventId: string, additionalRegistrations: number) => {
    try {
      // First get the current event
      const event = await databases.getDocument(
        DATABASE_ID,
        COLLECTIONS.EVENTS,
        eventId
      ) as Event;

      // Update the registered count
      const response = await databases.updateDocument(
        DATABASE_ID,
        COLLECTIONS.EVENTS,
        eventId,
        {
          registered: (event.registered || 0) + additionalRegistrations,
          updatedAt: new Date().toISOString()
        }
      );

      return response as Event;
    } catch (error) {
      console.error('Failed to update event registration:', error);
      // Don't throw the error, just log it
      return null;
    }
  },

  async updateRegistration(eventId: string, quantity: number) {
    try {
      // First get the current event to check capacity
      const event = await databases.getDocument(
        DATABASE_ID,
        COLLECTIONS.EVENTS,
        eventId
      );

      const newRegisteredCount = (event.registered || 0) + quantity;

      // Check if there's enough capacity
      if (newRegisteredCount > event.capacity) {
        throw new Error('Not enough tickets available');
      }

      // Update the registration count
      const updatedEvent = await databases.updateDocument(
        DATABASE_ID,
        COLLECTIONS.EVENTS,
        eventId,
        {
          registered: newRegisteredCount,
          status: newRegisteredCount >= event.capacity ? 'sold_out' : 'published',
          updatedAt: new Date().toISOString()
        }
      );

      return updatedEvent;
    } catch (error) {
      console.error('Failed to update event registration:', error);
      throw error;
    }
  }
};

// Booking Services
export const bookingService = {
  // Create a booking
  create: async (data: Omit<Booking, 'id' | '$id' | '$createdAt' | '$updatedAt'>) => {
    try {
      console.log('Creating booking with data:', data);
      const response = await databases.createDocument(
        DATABASE_ID,
        COLLECTIONS.BOOKINGS,
        ID.unique(),
        data
      );
      console.log('Booking created:', response);
      return response as Booking;
    } catch (error) {
      console.error('Failed to create booking:', error);
      throw error;
    }
  },

  // List user's bookings
  listUserBookings: async (userId: string) => {
    const response = await databases.listDocuments(
      DATABASE_ID,
      COLLECTIONS.BOOKINGS,
      [
        Query.equal('userId', userId),
        Query.orderDesc('$createdAt')
      ]
    );
    return response.documents as Booking[];
  },

  // Update booking status
  updateStatus: async (id: string, status: Booking['status']) => {
    const response = await databases.updateDocument(
      DATABASE_ID,
      COLLECTIONS.BOOKINGS,
      id,
      { status }
    );
    return response as Booking;
  },

  // Get a single booking
  get: async (bookingId: string) => {
    try {
      const response = await databases.getDocument(
        DATABASE_ID,
        COLLECTIONS.BOOKINGS,
        bookingId
      );
      return response as Booking;
    } catch (error) {
      console.error('Failed to fetch booking:', error);
      throw error;
    }
  },
}; 