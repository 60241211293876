import { loadStripe } from '@stripe/stripe-js';

let stripePromise: Promise<any> | null = null;

export const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);
  }
  return stripePromise;
};

interface CreateCheckoutSessionParams {
  eventId: string;
  userId?: string;
  quantity: number;
  unitAmount: number;
  title: string;
  imageUrl?: string;
  date: string;
  time: string;
  customerEmail?: string;
  restaurant?: {
    name?: string;
    address?: string;
  };
}

export const stripeService = {
  async createCheckoutSession({
    eventId,
    userId,
    quantity,
    unitAmount,
    title,
    imageUrl,
    date,
    time,
    customerEmail,
    restaurant
  }: CreateCheckoutSessionParams) {
    try {
      const response = await fetch('/.netlify/functions/createCheckoutSession', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          eventId,
          userId,
          quantity,
          unitAmount,
          title,
          imageUrl,
          date,
          time,
          customerEmail,
          restaurant
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to create checkout session');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error creating checkout session:', error);
      throw error;
    }
  }
}; 