import React, { useEffect, useState } from 'react';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import { Client, Functions, Models } from 'appwrite';
import { CheckCircle } from 'lucide-react';
import { Button } from '../components/ui/Button';
import { format } from 'date-fns';
import { bookingService } from '../services/booking';
import { eventService } from '../services/database';
import { useAuth } from '../contexts/AuthContext';
import type { Booking, Event } from '../types/database';

// Add type definition for function execution
interface ExtendedExecution extends Models.Execution {
  response: string;
}

// Add type definition for user
interface AppwriteUser extends Models.User<Models.Preferences> {
  $id: string;
}

export function BookingConfirmationPage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const bookingId = searchParams.get('booking_id');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [booking, setBooking] = useState<Booking | null>(null);
  const [event, setEvent] = useState<Event | null>(null);
  const { user } = useAuth();

  useEffect(() => {
    async function fetchBookingDetails() {
      if (!bookingId) {
        setError('Missing booking ID');
        setLoading(false);
        return;
      }

      try {
        // Fetch booking details
        const bookingData = await bookingService.getBooking(bookingId);
        setBooking(bookingData);

        // Fetch event details
        const eventData = await eventService.get(bookingData.eventId);
        setEvent(eventData);

        setLoading(false);
      } catch (err) {
        console.error('Error fetching booking details:', err);
        setError('Failed to load booking details');
        setLoading(false);
      }
    }

    fetchBookingDetails();
  }, [bookingId]);

  if (!bookingId) {
    return (
      <div className="max-w-2xl mx-auto px-4 py-8 text-center">
        <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-6">
          Invalid booking. Please try again.
        </div>
        <Button onClick={() => navigate('/events')}>
          Return to Events
        </Button>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#29303D]"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-2xl mx-auto px-4 py-8 text-center">
        <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-6">
          {error}
        </div>
        <Button onClick={() => navigate('/events')}>
          Return to Events
        </Button>
      </div>
    );
  }

  if (!booking || !event) {
    return (
      <div className="max-w-2xl mx-auto px-4 py-8 text-center">
        <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-6">
          Booking not found
        </div>
        <Button onClick={() => navigate('/events')}>
          Return to Events
        </Button>
      </div>
    );
  }

  return (
    <div className="max-w-2xl mx-auto px-4 py-8">
      <div className="text-center mb-8">
        <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-green-100 mb-4">
          <CheckCircle className="w-8 h-8 text-green-600" />
        </div>
        <h1 className="text-2xl font-bold text-gray-900 mb-2">Booking Confirmed!</h1>
        <p className="text-gray-600">
          Thank you for your booking. A confirmation email has been sent to your email address.
        </p>
      </div>

      <div className="bg-white shadow rounded-lg overflow-hidden mb-6">
        <div className="p-6">
          <h2 className="text-xl font-semibold text-gray-900 mb-4">Event Details</h2>
          <div className="space-y-4">
            <div>
              <h3 className="font-medium text-gray-900">Event</h3>
              <p className="text-gray-600">{event.title}</p>
            </div>
            <div>
              <h3 className="font-medium text-gray-900">Date & Time</h3>
              <p className="text-gray-600">
                {format(new Date(event.date), 'MMMM d, yyyy')} at {event.time}
              </p>
            </div>
            <div>
              <h3 className="font-medium text-gray-900">Location</h3>
              <p className="text-gray-600">{event.restaurant?.name || event.restaurantName || 'Restaurant information not available'}</p>
              <p className="text-gray-600">{event.restaurant?.address || event.address || 'Address not available'}</p>
              {event.restaurant?.phone && (
                <p className="text-gray-600">Phone: {event.restaurant.phone}</p>
              )}
              {event.restaurant?.website && (
                <p className="text-gray-600">
                  <a href={event.restaurant.website} target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:underline">
                    Visit website
                  </a>
                </p>
              )}
            </div>
            <div>
              <h3 className="font-medium text-gray-900">Number of Tickets</h3>
              <p className="text-gray-600">{booking.quantity}</p>
            </div>
            <div>
              <h3 className="font-medium text-gray-900">Total Amount</h3>
              <p className="text-gray-600">${booking.totalAmount.toFixed(2)}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center space-x-4">
        {user ? (
          <Button onClick={() => navigate('/bookings')}>
            View My Bookings
          </Button>
        ) : (
          <Button onClick={() => navigate(`/login?redirect=/bookings&booking_id=${bookingId}`)}>
            Sign up to view bookings
          </Button>
        )}
        <Button variant="outline" onClick={() => navigate('/events')}>
          Browse More Events
        </Button>
      </div>
    </div>
  );
} 