export function formatDate(date: string | Date): string {
  const d = new Date(date);
  return d.toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
}

export function formatTime(time: string): string {
  // First, remove any timezone information for processing
  let timeWithoutTimezone = time;
  let timezone = '';
  
  // Extract timezone if present
  const timezoneMatch = time.match(/(EST|PT|PST|MST|CST|EDT|PDT|MDT|CDT)$/i);
  if (timezoneMatch) {
    timezone = timezoneMatch[0];
    timeWithoutTimezone = time.replace(timezoneMatch[0], '').trim();
  }

  // Handle different time formats
  let hours: string;
  let minutes: string;
  let period: string | null = null;
  
  if (timeWithoutTimezone.includes(':')) {
    // Format: "HH:MM" or "HH:MM AM/PM"
    const ampmMatch = timeWithoutTimezone.match(/(\d+):(\d+)\s*(AM|PM)?/i);
    if (ampmMatch) {
      hours = ampmMatch[1];
      minutes = ampmMatch[2];
      period = ampmMatch[3] ? ampmMatch[3].toUpperCase() : null;
    } else {
      // Just fall back to original if we can't parse
      return time;
    }
  } else if (timeWithoutTimezone.includes('AM') || timeWithoutTimezone.includes('PM')) {
    // Format: "HH AM/PM" or similar
    const timeMatch = timeWithoutTimezone.match(/(\d+)(?::(\d+))?\s*(AM|PM)/i);
    if (!timeMatch) return time;
    
    hours = timeMatch[1];
    minutes = timeMatch[2] || '00';
    period = timeMatch[3].toUpperCase();
  } else {
    // No AM/PM indicator, assume 24-hour format
    const timeMatch = timeWithoutTimezone.match(/(\d+)(?::(\d+))?/);
    if (!timeMatch) return time;
    
    hours = timeMatch[1];
    minutes = timeMatch[2] || '00';
  }

  // Convert to numbers for manipulation
  let hour = parseInt(hours, 10);
  
  // If period is specified, ensure 12-hour format
  if (period) {
    // Already in 12-hour format with AM/PM
    if (period === 'PM' && hour !== 12) {
      hour = hour + 12;
    } else if (period === 'AM' && hour === 12) {
      hour = 0;
    }
  }
  
  // Format for 12-hour display
  const displayHour = hour % 12 || 12;
  const ampm = hour >= 12 ? 'PM' : 'AM';
  
  // Format minutes to always have two digits
  const formattedMinutes = minutes.padStart(2, '0');
  
  // Return formatted time with timezone if originally present
  const formattedTime = `${displayHour}:${formattedMinutes} ${ampm}`;
  return timezone ? `${formattedTime} ${timezone}` : formattedTime;
}

export function formatEventTime(time: string, timezone?: string): string {
  const formattedTime = formatTime(time);
  
  // If explicit timezone is provided and time doesn't already have it
  if (timezone && !formattedTime.includes(timezone)) {
    return `${formattedTime} ${timezone}`;
  }
  
  return formattedTime;
} 