import React, { useState } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { Button } from '../ui/Button';

interface LoginFormProps {
  onLoginSuccess: () => void;
  onSignupSuccess: () => void;
}

// Error messages for different scenarios
const ERROR_MESSAGES = {
  INVALID_EMAIL: 'Please enter a valid email address',
  INVALID_PASSWORD: 'Password must be at least 6 characters long',
  USER_NOT_FOUND: 'No account found with this email',
  WRONG_PASSWORD: 'Incorrect password',
  NETWORK_ERROR: 'Network error. Please check your connection and try again',
  TOO_MANY_ATTEMPTS: 'Too many failed attempts. Please try again later',
  DEFAULT: 'An unexpected error occurred. Please try again',
  GOOGLE_POPUP_CLOSED: 'Google sign-in was cancelled',
  MISSING_SCOPE: 'Authentication error. Please try again.',
} as const;

export function LoginForm({ onLoginSuccess, onSignupSuccess }: LoginFormProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { signInWithEmail, signInWithGoogle } = useAuth();
  const [email, setEmail] = useState(location.state?.email || '');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [attemptCount, setAttemptCount] = useState(0);
  const [lastAttemptTime, setLastAttemptTime] = useState(0);
  const [searchParams] = useSearchParams();
  const returnTo = searchParams.get('returnTo');
  const redirect = searchParams.get('redirect');
  const bookingId = searchParams.get('booking_id');

  const from = (location.state as any)?.from?.pathname || '/profile';

  // Validate email format
  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Rate limiting check
  const isRateLimited = (): boolean => {
    const now = Date.now();
    const timeSinceLastAttempt = now - lastAttemptTime;
    return attemptCount >= 5 && timeSinceLastAttempt < 300000; // 5 minutes cooldown
  };

  // Handle authentication errors
  const handleAuthError = (error: any): string => {
    console.error('Authentication error:', error);

    // Check for Appwrite specific errors
    if (error.message?.includes('missing scope')) {
      return ERROR_MESSAGES.MISSING_SCOPE;
    }

    // Check for type errors that might indicate implementation issues
    if (error instanceof TypeError) {
      return ERROR_MESSAGES.DEFAULT;
    }

    // Network error check
    if (!navigator.onLine) {
      return ERROR_MESSAGES.NETWORK_ERROR;
    }

    return error.message || ERROR_MESSAGES.DEFAULT;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Input validation
    if (!email || !password) {
      setError('Please enter both email and password');
      return;
    }

    if (!isValidEmail(email)) {
      setError(ERROR_MESSAGES.INVALID_EMAIL);
      return;
    }

    if (password.length < 6) {
      setError(ERROR_MESSAGES.INVALID_PASSWORD);
      return;
    }

    // Rate limiting
    if (isRateLimited()) {
      setError(ERROR_MESSAGES.TOO_MANY_ATTEMPTS);
      return;
    }

    setLoading(true);
    setError('');

    try {
      await signInWithEmail(email, password);
      setAttemptCount(0); // Reset attempt count on success
      onLoginSuccess();
    } catch (err: any) {
      setAttemptCount(prev => prev + 1);
      setLastAttemptTime(Date.now());
      setError(handleAuthError(err));
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    if (isRateLimited()) {
      setError(ERROR_MESSAGES.TOO_MANY_ATTEMPTS);
      return;
    }

    try {
      setError('');
      setLoading(true);
      
      // If we have a booking ID, store it in localStorage for after auth
      if (bookingId) {
        localStorage.setItem('postGoogleAuthBookingId', bookingId);
      }
      
      // If we have a redirect URL, store it in localStorage for after auth
      if (redirect) {
        localStorage.setItem('postGoogleAuthRedirect', redirect);
      }
      
      // If we have a returnTo URL, store it in localStorage for after auth
      if (returnTo) {
        localStorage.setItem('postGoogleAuthReturnTo', returnTo);
      }
      
      // Call the signInWithGoogle function which will redirect to Google OAuth
      await signInWithGoogle();
      
      // This code won't execute due to the redirect
      setAttemptCount(0);
      onLoginSuccess();
    } catch (err: any) {
      setAttemptCount(prev => prev + 1);
      setLastAttemptTime(Date.now());
      setError(handleAuthError(err));
    } finally {
      setLoading(false);
    }
  };

  // Cleanup function for unmounting
  React.useEffect(() => {
    return () => {
      setLoading(false);
      setError('');
    };
  }, []);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-purple-50 to-indigo-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white p-8 rounded-xl shadow-lg">
        <div>
          <h2 className="text-center text-3xl font-bold text-gray-900">
            Sign in to Try Everything
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Experience Canada's exclusive dining community
          </p>
        </div>

        {error && (
          <div className="bg-red-50 text-red-700 p-4 rounded-md text-sm" role="alert" aria-live="polite">
            {error}
          </div>
        )}

        <form className="mt-8 space-y-6" onSubmit={handleSubmit} noValidate>
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email" className="sr-only">
                Email address
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                placeholder="Email address"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setError(''); // Clear error when user starts typing
                }}
                disabled={loading}
                aria-invalid="false"
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                placeholder="Password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setError(''); // Clear error when user starts typing
                }}
                disabled={loading}
                aria-invalid="false"
              />
            </div>
          </div>

          <Button
            type="submit"
            disabled={loading || isRateLimited()}
            className="w-full flex justify-center py-2 px-4"
            aria-busy={loading}
          >
            {loading ? 'Signing in...' : 'Sign in'}
          </Button>
        </form>

        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-white text-gray-500">Or continue with</span>
          </div>
        </div>

        <Button
          type="button"
          variant="outline"
          onClick={handleGoogleSignIn}
          disabled={loading || isRateLimited()}
          className="w-full flex items-center justify-center gap-2"
          aria-busy={loading}
        >
          <img
            src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
            alt="Google"
            className="w-5 h-5"
          />
          {loading ? 'Signing in...' : 'Sign in with Google'}
        </Button>

        <div className="text-center text-sm">
          <p className="text-gray-600">
            Don't have an account?{' '}
            <button
              onClick={() => {
                // Pass along booking_id when going to signup
                if (bookingId) {
                  navigate(`/signup?booking_id=${bookingId}${redirect ? `&redirect=${redirect}` : ''}`);
                } else {
                  navigate('/signup');
                }
              }}
              className="text-purple-600 hover:text-purple-500 font-medium"
              type="button"
              disabled={loading}
            >
              Sign up
            </button>
          </p>
        </div>
      </div>
    </div>
  );
}