import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { Menu as MenuIcon, X, ChevronDown } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { Button } from './ui/Button';
import logo from '../assets/dish.jpeg';
import { databases } from '../lib/appwrite';
import { appwriteConfig } from '../config/appwrite';
import { Query } from 'appwrite';

interface UserDocument {
  $id: string;
  userId: string;
  name: string;
  email: string;
  phone?: string;
  avatar?: string;
}

export function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { user, signOut } = useAuth();
  const [userDocument, setUserDocument] = useState<UserDocument | null>(null);

  useEffect(() => {
    async function fetchUserDocument() {
      if (!user) return;
      
      try {
        const response = await databases.listDocuments(
          appwriteConfig.databaseId,
          appwriteConfig.usersCollectionId,
          [Query.equal('userId', user.$id)]
        );

        if (response.documents.length > 0) {
          const doc = response.documents[0];
          setUserDocument({
            $id: doc.$id,
            userId: doc.userId,
            name: doc.name,
            email: doc.email,
            phone: doc.phone,
            avatar: doc.avatar
          } as UserDocument);
        }
      } catch (error) {
        console.error('Failed to fetch user document:', error);
      }
    }

    fetchUserDocument();
  }, [user]);

  const handleLogout = async () => {
    try {
      await signOut();
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <header className="bg-gray-900 sticky top-0 z-50">
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo and Brand */}
          <Link to="/" className="flex items-center space-x-3">
            <img 
              src={logo} 
              alt="Try Everything Logo" 
              className="h-10 w-10 rounded-full object-cover"
            />
            <span className="text-2xl font-bold text-white">
              Try Everything
            </span>
          </Link>
          
          {/* Desktop Navigation - Right Aligned */}
          <div className="hidden md:flex items-center space-x-6 ml-auto">
            <Link 
              to="/events" 
              className="text-white hover:text-white/80 transition-colors font-medium"
            >
              Events
            </Link>
            <Link 
              to="/restaurants" 
              className="text-white hover:text-white/80 transition-colors font-medium"
            >
              Restaurants
            </Link>
            <Link 
              to="/partner" 
              className="text-white hover:text-white/80 transition-colors font-medium"
            >
              Partner With Us
            </Link>
            {user && (
              <Link 
                to="/bookings" 
                className="text-white hover:text-white/80 transition-colors font-medium"
              >
                My Bookings
              </Link>
            )}
            
            {user ? (
              <div className="relative inline-block text-left">
                <Menu as="div">
                  <div>
                    <Menu.Button className="flex items-center space-x-2 text-white hover:text-white/80 transition-colors">
                      <img 
                        src={userDocument?.avatar || user.avatar || `https://ui-avatars.com/api/?name=${encodeURIComponent(user.name)}`}
                        alt={user.name}
                        className="h-8 w-8 rounded-full object-cover"
                      />
                      <span className="font-medium">{user.name}</span>
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="/settings"
                            className={`${
                              active ? 'bg-gray-100' : ''
                            } block px-4 py-2 text-sm text-gray-700`}
                          >
                            Settings
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={handleLogout}
                            className={`${
                              active ? 'bg-gray-100' : ''
                            } block w-full text-left px-4 py-2 text-sm text-gray-700`}
                          >
                            Sign Out
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            ) : (
              <Link 
                to="/login" 
                className="bg-white/10 text-white px-6 py-2 rounded-full hover:bg-white/20 transition-colors font-medium"
              >
                Sign In
              </Link>
            )}
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-white p-2 focus:outline-none"
            >
              {isMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <MenuIcon className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="md:hidden bg-[#29303D] p-4 space-y-4">
            <Link 
              to="/events"
              className="block text-white hover:text-white/80 transition-colors font-medium"
              onClick={() => setIsMenuOpen(false)}
            >
              Events
            </Link>
            <Link 
              to="/restaurants"
              className="block text-white hover:text-white/80 transition-colors font-medium"
              onClick={() => setIsMenuOpen(false)}
            >
              Restaurants
            </Link>
            <Link 
              to="/partner"
              className="block text-white hover:text-white/80 transition-colors font-medium"
              onClick={() => setIsMenuOpen(false)}
            >
              Partner With Us
            </Link>
            {user && (
              <Link 
                to="/bookings"
                className="block text-white hover:text-white/80 transition-colors font-medium"
                onClick={() => setIsMenuOpen(false)}
              >
                My Bookings
              </Link>
            )}

            {user ? (
              <>
                <div className="flex items-center space-x-2 mb-4">
                  <img 
                    src={userDocument?.avatar || user.avatar || `https://ui-avatars.com/api/?name=${encodeURIComponent(user.name)}`}
                    alt={user.name}
                    className="h-8 w-8 rounded-full object-cover"
                  />
                  <span className="text-white font-medium">{user.name}</span>
                </div>
                <Link 
                  to="/settings"
                  className="block text-gray-300 hover:text-white font-medium"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Settings
                </Link>
                <button
                  onClick={() => {
                    handleLogout();
                    setIsMenuOpen(false);
                  }}
                  className="block w-full text-left text-gray-300 hover:text-white font-medium"
                >
                  Sign Out
                </button>
              </>
            ) : (
              <Link 
                to="/login" 
                className="block bg-white/10 text-white px-6 py-2 rounded-full hover:bg-white/20 transition-colors font-medium text-center"
                onClick={() => setIsMenuOpen(false)}
              >
                Sign In
              </Link>
            )}
          </div>
        )}
      </nav>
    </header>
  );
}