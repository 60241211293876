import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { Button } from '../components/ui/Button';
import { Card } from '../components/ui/Card';
import { bookingService } from '../services/booking';
import { eventService } from '../services/database';
import type { Booking, Event } from '../types/database';

export function BookingDetailsPage() {
  const { bookingId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [booking, setBooking] = useState<Booking | null>(null);
  const [event, setEvent] = useState<Event | null>(null);

  useEffect(() => {
    async function fetchBookingDetails() {
      if (!bookingId) {
        setError('Missing booking ID');
        setLoading(false);
        return;
      }

      try {
        // Fetch booking details
        const bookingData = await bookingService.getBooking(bookingId);
        setBooking(bookingData);

        // Fetch event details
        const eventData = await eventService.get(bookingData.eventId);
        setEvent(eventData);

        setLoading(false);
      } catch (err) {
        console.error('Error fetching booking details:', err);
        setError('Failed to load booking details');
        setLoading(false);
      }
    }

    fetchBookingDetails();
  }, [bookingId]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#29303D]"></div>
      </div>
    );
  }

  if (error || !booking || !event) {
    return (
      <div className="max-w-2xl mx-auto px-4 py-8 text-center">
        <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-6">
          {error || 'Booking not found'}
        </div>
        <Button onClick={() => navigate('/bookings')}>
          Return to Bookings
        </Button>
      </div>
    );
  }

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'attending':
        return 'bg-green-100 text-green-800';
      case 'cancelled':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="max-w-3xl mx-auto px-4 py-8">
      <div className="mb-6 flex items-center justify-between">
        <h1 className="text-3xl font-bold text-gray-900">Booking Details</h1>
        <span className={`px-4 py-2 rounded-full text-sm font-medium ${getStatusColor(booking.status)}`}>
          {booking.status}
        </span>
      </div>

      <Card className="overflow-hidden mb-8">
        <div className="relative h-64">
          <img
            src={event.imageUrl || 'https://via.placeholder.com/800x400'}
            alt={event.title}
            className="w-full h-full object-cover"
          />
        </div>
        <div className="p-6">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">{event.title}</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-4">
              <div>
                <h3 className="text-sm font-medium text-gray-500">Date & Time</h3>
                <p className="mt-1 text-gray-900">
                  {format(new Date(event.date), 'MMMM d, yyyy')} at {event.time}
                </p>
              </div>
              
              <div>
                <h3 className="text-sm font-medium text-gray-500">Location</h3>
                <p className="mt-1 text-gray-900">{event.restaurant?.name || event.restaurantName || 'Restaurant information not available'}</p>
                <p className="text-gray-600">{event.restaurant?.address || event.address || 'Address not available'}</p>
                {event.restaurant?.phone && (
                  <p className="text-gray-600">Phone: {event.restaurant.phone}</p>
                )}
                {event.restaurant?.website && (
                  <p className="text-gray-600">
                    <a href={event.restaurant.website} target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:underline">
                      Visit website
                    </a>
                  </p>
                )}
              </div>
            </div>

            <div className="space-y-4">
              <div>
                <h3 className="text-sm font-medium text-gray-500">Number of Tickets</h3>
                <p className="mt-1 text-gray-900">{booking.quantity}</p>
              </div>

              <div>
                <h3 className="text-sm font-medium text-gray-500">Total Amount</h3>
                <p className="mt-1 text-gray-900">${booking.totalAmount.toFixed(2)}</p>
              </div>

              <div>
                <h3 className="text-sm font-medium text-gray-500">Booking ID</h3>
                <p className="mt-1 text-gray-600 font-mono text-sm">{booking.$id}</p>
              </div>

              <div>
                <h3 className="text-sm font-medium text-gray-500">Booked On</h3>
                <p className="mt-1 text-gray-600">
                  {format(new Date(booking.createdAt), 'MMMM d, yyyy')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Card>

      <div className="flex justify-between">
        <Button variant="outline" onClick={() => navigate('/bookings')}>
          Back to Bookings
        </Button>
        {booking.status.toLowerCase() === 'attending' && (
          <Button variant="outline" onClick={() => window.print()}>
            Print Ticket
          </Button>
        )}
      </div>
    </div>
  );
} 