import React, { useState } from 'react';
import { X } from 'lucide-react';
import { Button } from './Button';
import { useNavigate } from 'react-router-dom';

interface WaitlistDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export function WaitlistDialog({ isOpen, onClose }: WaitlistDialogProps) {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('form-name', 'waitlist');
      formData.append('email', email);

      await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(formData as any).toString()
      });

      setSubmitted(true);
      setEmail('');
      setTimeout(() => {
        onClose();
        setTimeout(() => setSubmitted(false), 300);
      }, 2000);
    } catch (error) {
      console.error('Form submission error:', error);
    }
  };

  const handleViewEvents = () => {
    onClose();
    navigate('/events');
  };

  const handleViewRestaurants = () => {
    onClose();
    navigate('/restaurants');
  };

  return (
    <div className={`
      fixed inset-0 flex items-center justify-center z-50
      transition-opacity duration-300
      ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}
    `}>
      <div className="absolute inset-0 bg-black/50 backdrop-blur-sm" onClick={onClose} />
      <div className={`
        relative bg-white rounded-2xl p-8 max-w-md w-full mx-4
        transform transition-all duration-300 shadow-xl
        ${isOpen ? 'scale-100' : 'scale-95'}
      `}>
        <button 
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
          type="button"
          aria-label="Close dialog"
        >
          <X className="h-6 w-6" />
        </button>

        {submitted ? (
          <div className="text-center py-8">
            <h3 className="text-2xl font-bold text-gray-900 mb-2">
              You're on the list!
            </h3>
            <p className="text-gray-600 mb-6">
              We'll notify you when new events are available.
            </p>
            <div className="space-y-3">
              <Button
                onClick={handleViewEvents}
                className="w-full bg-[#29303D] hover:bg-[#29303D]/90"
              >
                View Current Events
              </Button>
              <Button
                onClick={handleViewRestaurants}
                variant="outline"
                className="w-full"
              >
                Browse Restaurants
              </Button>
            </div>
          </div>
        ) : (
          <form 
            onSubmit={handleSubmit} 
            name="waitlist" 
            method="POST" 
            data-netlify="true"
            netlify-honeypot="bot-field"
            className="space-y-6"
          >
            <div className="text-center">
              <h3 className="text-2xl font-bold text-gray-900 mb-2">
                Join the Waitlist
              </h3>
              <p className="text-gray-600">
                Get notified when new events are available.
              </p>
            </div>

            <input type="hidden" name="form-name" value="waitlist" />
            <div hidden>
              <input name="bot-field" />
            </div>
            <div className="flex gap-2">
              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="flex-1 px-4 py-3 border border-gray-300 rounded-full focus:ring-2 focus:ring-[#6E38DE] focus:border-[#6E38DE] outline-none"
              />
              <Button
                type="submit"
                className="bg-gradient-to-r from-[#6E38DE] to-[#8B5CF6] hover:from-[#5E2EC7] hover:to-[#7C4FE3] text-white rounded-full px-8"
              >
                Join →
              </Button>
            </div>
            <div className="flex justify-center space-x-4 mt-4">
              <Button
                type="button"
                variant="outline"
                onClick={handleViewEvents}
                className="text-sm"
              >
                View Events
              </Button>
              <Button
                type="button"
                variant="outline"
                onClick={handleViewRestaurants}
                className="text-sm"
              >
                View Restaurants
              </Button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
} 